import clsx from "clsx";
import Spinner from "components/icon/Spinner";
import Link from "next/link";
import { SyntheticEvent } from "react";
import { useDebounce } from "use-debounce";
interface Props {
  bgColorClass?: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  disabledClass?: string;
  hoverClass?: string;
  href?: string;
  leftIcon?: React.ReactNode;
  loading?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  opacityClass?: string;
  paddingXClass?: string;
  size?: "medium" | "large";
  type?: "button" | "submit";
  widthClass?: string;
  withGroupFlow?: boolean;
}
export default function ActionButton(props: Props) {
  const {
    children,
    href,
    leftIcon,
    onClick,
    type,
    widthClass
  } = props;
  const [loading] = useDebounce(props.loading ?? false, 500);
  const disabled = props.disabled || props.loading;
  const heightClass = props.type === "submit" || props.size === "large" ? "h-12" : "h-9";
  const paddingX = props.paddingXClass || (props.type === "submit" ? "px-6" : "px-5");
  const hoverClass = disabled ? undefined : props.hoverClass || "hover:saturate-150 hover:brightness-125";
  let baseBgColorClass = "bg-gradient-to-r from-new-green-500 to-new-green-600";
  let bgColorClass = disabled ? props.disabledClass || clsx(baseBgColorClass, "saturate-50 cursor-not-allowed") : baseBgColorClass;
  if (props.bgColorClass) {
    bgColorClass = props.bgColorClass;
  }
  const opacityClass = disabled ? undefined : props.opacityClass;
  const className = clsx("flex items-center justify-center gap-x-2", widthClass, heightClass, paddingX, "rounded-full", bgColorClass, opacityClass, hoverClass, "text-white", "font-medium", "transition", props.className);
  let icon = null;
  if (loading) {
    icon = <Spinner className="h-5 w-5 mr-2" />;
  } else if (props.withGroupFlow) {
    icon = <img src="https://mp1md-pub.s3.amazonaws.com/groupflow/images/icon-white.svg" alt="GroupFlow" className="w-6 h-6" />;
  } else {
    icon = leftIcon;
  }
  const content = <>
      {icon}
      {children}
    </>;
  if (href) {
    if (href.startsWith("http")) {
      return <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
          {content}
        </a>;
    } else {
      return <Link href={href} className={className}>
          {content}
        </Link>;
    }
  }
  return <button type={type} disabled={disabled} className={className} onClick={onClick} data-sentry-component="ActionButton" data-sentry-source-file="ActionButton.tsx">
      {content}
    </button>;
}
// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ProgressIndicator from "components/ProgressIndicator";
import { SecondaryButton } from "components/ui";
import { ReactElement, SyntheticEvent } from "react";
import ActionButton from "./ActionButton";
import clsx from "clsx";
import { ThemeArea } from "types/theme";
import AdminSubmitButtonRow from "components/admin/ui/SubmitButtonRow";
interface Props {
  cancel?: {
    onClick?: () => void;
    href?: string;
    label?: string | ReactElement;
  };
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  marginTopClass?: string;
  label?: string | ReactElement;
  onClick?: (e: SyntheticEvent) => void;
  saving?: boolean;
  themeArea?: ThemeArea;
  withGroupFlow?: boolean;
}
export default function SubmitButtonRow(props: Props) {
  const {
    cancel,
    children,
    className,
    disabled,
    saving,
    themeArea,
    withGroupFlow
  } = props;
  if (themeArea === "admin") {
    return <AdminSubmitButtonRow {...props} />;
  }
  const marginTopClass = props.marginTopClass ?? "mt-4";
  const handleClick = cancel?.onClick;
  return <div className={clsx("SubmitButtonRow flex gap-2 justify-between", marginTopClass, className)} data-sentry-component="SubmitButtonRow" data-sentry-source-file="SubmitButtonRow.tsx">
      {cancel && <SecondaryButton onClick={handleClick} href={cancel.href} className="grow">
          {cancel.label || "Cancel"}
        </SecondaryButton>}
      {saving && <ProgressIndicator />}
      {!saving && <ActionButton type={props.onClick ? "button" : "submit"} disabled={disabled || false} loading={saving} onClick={props.onClick} className="grow" withGroupFlow={withGroupFlow}>
          {children || props.label || "Save"}
        </ActionButton>}
    </div>;
}
/*!
 * The expanded part of a Hamburger menu.

 * This navigation menu is displayed on the side of the screen for mobile screens.
 */

import { observer } from "mobx-react-lite";
import Navigation from "components/webComponent/presets/Navigation";
import { useHamburgerStore } from "lib/gf-app-context";
function SideCar() {
  const store = useHamburgerStore();
  if (!store) return null;
  if (!store.isOpen) return null;
  return <nav className="SideCar block fixed top-0 right-0" style={{
    width: "16rem",
    height: "100%"
  }} data-sentry-component="SideCar" data-sentry-source-file="SideCar.tsx">
      <Navigation navMenuId={store.navItemId} navMenuSlug={store.navItemSlug} accountMenuInline purpose="sidebar" data-sentry-element="Navigation" data-sentry-source-file="SideCar.tsx" />
    </nav>;
}
export default observer(SideCar);
import { SwatchIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import SimpleModal from "components/SimpleModal";
import { ColumnLabel } from "components/form";
import Select from "components/form/Select";
import useGroupSwitcher from "hooks/useGroupSwitcher";
import { ADMIN_STYLE } from "lib/Style";
import { useLogin } from "lib/auth";
import { useAppContext } from "lib/gf-app-context";
import nextConfigFn from "next/config";
import { SyntheticEvent, useState } from "react";
const nextConfig = nextConfigFn();
export default function GroupSwitcher() {
  const {
    groupSwitcherEnabled
  } = nextConfig.publicRuntimeConfig;
  if (!groupSwitcherEnabled) return null;
  const [isOpen, setIsOpen] = useState(false);
  const {
    groups
  } = useAppContext();
  const groupOptions = groups?.map(group => ({
    label: group.name,
    value: group.slug
  }));
  const {
    groupSlug,
    setGroup
  } = useGroupSwitcher();
  const [visible, setVisible] = useState(true);
  const usingLogin = useLogin();
  function onChange(e: SyntheticEvent) {
    const slug = (e.target as any).value;
    setGroup(slug);
    usingLogin.logout();
    window.location.reload();
  }
  if (!visible) return null;
  return <>
      <button className="GroupSwitcher-button hidden md:block fixed top-[50%] left-0 bg-white rounded-r-md border opacity-80 py-2 px-4 group" onClick={() => setIsOpen(true)}>
        <SwatchIcon className="h-6 w-6 text-gf-green-dark group-hover:text-gf-green" data-sentry-element="SwatchIcon" data-sentry-source-file="GroupSwitcher.tsx" />
      </button>
      <SimpleModal isOpen={isOpen} onClose={() => setIsOpen(false)} themeArea="admin" data-sentry-element="SimpleModal" data-sentry-source-file="GroupSwitcher.tsx">
        <div className="flex flex-col gap-6">
          <div className="text-lg font-bold">Group Switcher</div>
          <div>
            <ColumnLabel data-sentry-element="ColumnLabel" data-sentry-source-file="GroupSwitcher.tsx">Group:</ColumnLabel>
            <Select options={groupOptions} onChange={onChange} value={groupSlug} data-sentry-element="Select" data-sentry-source-file="GroupSwitcher.tsx" />
          </div>
          <div className="flex flex-col gap-4">
            <div className="text-lg font-bold">Hide Switcher</div>
            <div>
              <button className={clsx(ADMIN_STYLE.buttonShapeMedium, ADMIN_STYLE.buttonBasicColors)} onClick={() => setVisible(false)}>
                <XMarkIcon className="w-5 h-5" data-sentry-element="XMarkIcon" data-sentry-source-file="GroupSwitcher.tsx" />
                Hide
              </button>
            </div>

            <p>Refresh the page to bring the switcher back.</p>
          </div>
        </div>
      </SimpleModal>
    </>;
}
import { MutationInfo } from "types/ash";
import AshMutationErrors from "./AshMutationErrors";
import AshMutationSuccess from "./AshMutationSuccess";
interface Props<T> {
  successMessage: string;
  mutationInfo: MutationInfo<T> | undefined;
}
export default function AshMutationResult<T = Record<string, any>>(props: Props<T>) {
  if (!props.mutationInfo) {
    return null;
  }
  return <>
      <AshMutationErrors mutationInfo={props.mutationInfo} data-sentry-element="AshMutationErrors" data-sentry-source-file="AshMutationResult.tsx" />
      <AshMutationSuccess mutationInfo={props.mutationInfo} message={props.successMessage} data-sentry-element="AshMutationSuccess" data-sentry-source-file="AshMutationResult.tsx" />
    </>;
}
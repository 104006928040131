import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
export default function SplashScreen() {
  const router = useRouter();
  const [state, setState] = useState<"start" | "transition" | "hidden">("start");
  useEffect(() => {
    if (state === "start") {
      setTimeout(() => {
        setState("transition");
      }, 1000);
    } else if (state === "transition") {
      setTimeout(() => {
        setState("hidden");
      }, 1000);
    }
  }, [state]);
  let showClass = "";
  if (state === "transition") {
    showClass = "opacity-0";
  } else if (state === "hidden") {
    showClass = "hidden";
  }
  if (router.asPath.startsWith("/members/pages")) {
    return null;
  }
  return <div className={clsx("absolute inset-0 w-full h-full bg-white z-30 flex items-center justify-center text-black", "transition-opacity duration-1000", showClass)} data-sentry-component="SplashScreen" data-sentry-source-file="BrandLoading.tsx">
      <img src="https://mp1md-pub.s3.amazonaws.com/groupflow/images/logo-stacked.svg" alt="GroupFlow logo" className="w-[20rem]" />
    </div>;
}
import clsx from "clsx";
import HollowButton from "components/admin/ui/HollowButton";
import Link from "next/link";
import { ThemeArea } from "types/theme";
interface Props {
  href?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
  className?: string;
  leftIcon?: React.ReactNode;
  themeArea?: ThemeArea;
  type?: "button" | "submit" | "reset";
}
export default function SecondaryButton(props: Props) {
  let {
    className,
    children,
    leftIcon,
    themeArea,
    href,
    onClick,
    type = "button"
  } = props;
  let borderColorClass = "border-secondary-200 hover:border-secondary-300";
  className = clsx("SecondaryButton Button inline-flex flex-row gap-2 items-center justify-center", className, "transition", "font-semibold", "border", "py-2.5 px-4.5", "bg-white", "text-secondary-800 hover:text-black", "rounded-[var(--group-button-radius)]", borderColorClass);
  const supplementedChildren = <>
      {leftIcon} {children || "Cancel"}
    </>;
  if (themeArea === "admin") {
    return <HollowButton className={props.className} onClick={props.onClick}>
        {props.children}
      </HollowButton>;
  }
  if (href) {
    return <Link href={href} className={className}>
        {supplementedChildren}
      </Link>;
  }
  return <button className={className} onClick={onClick} type={type} data-sentry-component="SecondaryButton" data-sentry-source-file="SecondaryButton.tsx">
      {supplementedChildren}
    </button>;
}
import clsx from "clsx";
import { useThemeInfo } from "lib/theme";
import Link from "next/link";
import { ElementType } from "react";
interface Props {
  children: React.ReactNode;
  className?: string;
  component?: React.ElementType;
  disabled?: boolean;
  href?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  colorStyle?: "primary" | "primary2" | "members-primary2" | "secondary" | "warning";
  size?: "medium" | "large";
  type?: "button" | "submit" | "reset";
}
export default function HollowButton(props: Props) {
  const {
    children,
    className,
    colorStyle,
    disabled,
    onClick,
    type
  } = props;
  const themeInfo = useThemeInfo();
  let colorClass = "border-new-gray-200 hover:border-black disabled:border-new-gray-300/50 bg-white text-secondary-950 hover:text-black";
  if (colorStyle === "primary") {
    colorClass = "border-new-gray-400 hover:border-new-green-800 disabled:border-new-gray-400 bg-white text-primary-600 hover:text-primary-400";
  }
  if (colorStyle === "primary2") {
    // primary2 is used in the admin area. It has a ultralight background and grey foreground colors.
    colorClass = "border-new-gray-400 hover:border-new-green-800 disabled:border-new-gray-400 bg-gf-green-ultralight text-primary-450 hover:text-primary-700";
  } else if (colorStyle === "members-primary2") {
    // primary3 is used in the members area. It has a ultralight background and grey foreground colors.
    colorClass = "border-new-gray-400 hover:border-secondary-800 disabled:border-new-gray-400 bg-secondary-50 text-secondary-400 hover:text-secondary-700";
  } else if (colorStyle === "warning") {
    colorClass = "border-new-gray-400 hover:border-new-gray-700 disabled:border-new-gray-400 text-new-red-700 hover:text-new-red-500";
  }
  let heightClass = "h-9";
  if (themeInfo.themeArea === "admin") {
    heightClass = props.size === "medium" ? "h-9" : "h-12";
  }
  let Component: ElementType = (props: any) => <button {...props} data-sentry-component="Component" data-sentry-source-file="HollowButton.tsx" />;
  if (props.component) {
    Component = props.component;
  } else if (props.href) {
    Component = (props2: any) => <Link href={(props.href as string)} passHref {...props2} />;
  }
  return <Component type={type || "button"} disabled={disabled} className={clsx(heightClass, "inline-block flex justify-center items-center gap-2 rounded-full border shadow-sm px-5 transition whitespace-nowrap", colorClass, className)} onClick={onClick} data-sentry-element="Component" data-sentry-component="HollowButton" data-sentry-source-file="HollowButton.tsx">
      {children}
    </Component>;
}
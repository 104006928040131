import clsx from "clsx";
import EditableSection from "components/contentEditing/EditableSection";
import FormattedText from "components/FormattedText";
import { usePosts } from "hooks/content";
import { observer } from "mobx-react-lite";
import Link from "next/link";
import { SimplifiedContent } from "types/content";
interface Props {
  className?: string;
  postSlug?: string;
}
function Hero(props: Props) {
  const {
    className,
    postSlug
  } = props;
  const {
    getPostListBySlug: getPost
  } = usePosts();
  const postsItem = postSlug ? getPost(postSlug) : undefined;
  const headerItem = postsItem?.children?.find(c => c.purpose === "header") || {
    content: "Edit This Heading",
    contentType: "text/markdown"
  };
  const subHeaderItem = postsItem?.children?.find(c => c.purpose === "sub-header");
  const button1Item = postsItem?.children?.find(c => c.purpose === "button-1");
  const button2Item = postsItem?.children?.find(c => c.purpose === "button-2");
  const imageItem = postsItem?.children?.find(c => c.purpose === "image");
  return <EditableSection editById={postsItem ? {
    id: postsItem?.id,
    baseType: "text",
    webComponentName: "Hero"
  } : undefined} editBySlug={postSlug && !postsItem ? {
    slug: postSlug,
    baseType: "text",
    subType: "post",
    plurality: "item",
    webComponentName: "Hero"
  } : undefined} className={clsx(className, "hero-content")} data-sentry-element="EditableSection" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      {headerItem && <h1 className="hero-header">
          <FormattedText text={headerItem.content} contentType={headerItem.contentType} />
        </h1>}
      {subHeaderItem?.content && <h2 className="hero-subheader">
          <FormattedText text={subHeaderItem.content} contentType={subHeaderItem.contentType} />
        </h2>}
      {(button1Item?.label || button2Item?.label) && <div className="buttons">
          {button1Item?.label && <Link className="Button hero-button hero-button-1" href={button1Item.uri ?? "/"}>
              {button1Item.label}
            </Link>}
          {button2Item?.label && <Link className="Button hero-button hero-button-2" href={button2Item.uri ?? "/"}>
              {button2Item.label}
            </Link>}
        </div>}
      {imageItem?.photo && <img src={imageItem.photo.url} alt={`Image ${imageItem.photo.id}`} className="hero-image" />}
    </EditableSection>;
}
export default observer(Hero);
export const HERO_ATTRIBUTES = [{
  key: "postSlug",
  type: "string"
}];
export const HERO_CONTENT_SCHEMA: SimplifiedContent = {
  isParentEditable: false,
  items: [{
    purpose: "header",
    title: "Header",
    type: "text"
  }, {
    purpose: "sub-header",
    title: "Sub Header",
    type: "text"
  }, {
    purpose: "button-1",
    title: "Button 1",
    type: "button",
    baseType: "text"
  }, {
    purpose: "button-2",
    title: "Button 2",
    type: "button",
    baseType: "text"
  }, {
    purpose: "image",
    title: "Image",
    type: "image",
    baseType: "photo"
  }]
};
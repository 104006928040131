import { ADMIN_STYLE } from "lib/Style";
import { ReactNode } from "react";
import { ThemeArea } from "types/theme";
interface Props {
  children: ReactNode;
  themeArea?: ThemeArea;
}
export default function ModalHeading(props: Props) {
  const {
    children
  } = props;
  return <h2 className={ADMIN_STYLE.modal.heading} data-sentry-component="ModalHeading" data-sentry-source-file="ModalHeading.tsx">{children}</h2>;
}
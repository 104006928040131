// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import clsx from "clsx";
import Modal, { Styles } from "react-modal";
import { ThemeArea } from "types/theme";

(Modal.defaultStyles.content as any).position = "initial";

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(128, 128, 128, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "30",
  },
  content: {
    borderRadius: "1rem",
    maxWidth: "600px",
    maxHeight: "98%",
    width: "100%",
    color: "black",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
} as Styles;

const adminModalStyle = {
  overlay: {
    backgroundColor: "rgba(128, 128, 128, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "10",
  },
  content: {
    maxWidth: "600px",
    maxHeight: "98%",
    border: undefined,
    borderRadius: "12px",
    color: "black",
    fontFamily: "Montserrat",
    overflow: "auto",
  },
};

const buttonIcon = "fill-current w-[14px] h-[14px]";

interface ListItemStyleProps {
  backgroundClass?: string;
  borderClass?: string;
  clickable?: boolean;
  paddingClass?: string;
  shadowClass?: string;
}

export function listItemStyle(props: ListItemStyleProps) {
  const backgroundClass = props.backgroundClass ?? "bg-white";
  const borderClass = props.borderClass ?? "rounded-xl";
  const paddingClass = props.paddingClass ?? "p-4";
  const clickableClass = props.clickable
    ? "hover:border-gf-green-dark hover:cursor-pointer"
    : undefined;
  const shadowClass = props.shadowClass ?? "shadow-gf-sm";

  return clsx(
    shadowClass,
    borderClass,
    backgroundClass,
    paddingClass,
    clickableClass
  );
}

const Style = {
  cardMajor: "md:border md:rounded-xl border-slate-200 md:shadow-md bg-white",
  badge: "flex items-center gap-x-2 px-2 border rounded",
  buttonIcon,
  contentMinWidth: "md:min-w-[768px]",
  formClass: "flex flex-col gap-6",
  inlineLabel: "text-gray-darker pr-2 font-medium",
  labelText: "font-medium text-secondary-950",
  modalStyle,
  modalBodyStyle: "sm:min-w-[30rem] p-4 md:p-6",
  inputClass:
    "form-component theme-field-outline-color outline-[var(--group-accent)] border border-secondary-300 text-secondary-900",
  itemBorder: "rounded-2xl shadow-md",
  buttonDeleteBgColorClass:
    "bg-red-500 hover:bg-red-400 disabled:bg-red-500/50",
  sectionFieldsetLegend:
    "text-2xl font-semibold text-gray-darkest flex flex-row items-center gap-2",
  textArea:
    "form-component w-full border border-gray theme-field-outline-color outline-[var(--group-accent)]",
  radioWithLabel: "flex flex-row justify-start content-start mb-1",
};

export default Style;

export const ADMIN_STYLE = {
  bodyTextOnWhite: "text-primary-900",
  bodyTextOnOffWhite: "text-primary-950",
  buttonShapeSmall:
    "flex justify-center items-center gap-2 rounded-full px-5 py-2 transition text-sm",
  buttonShapeMedium:
    "flex justify-center items-center gap-2 rounded-full px-5 py-2 transition",
  buttonBasicColors:
    "border hover:border-black text-secondary-800 hover:text-black",
  buttonActionColors: "bg-gf-green-dark hover:bg-gf-green text-white",
  form: {
    smallLabelOnWhite: "text-primary-900 text-medium",
    smallLabelOnOffWhite: "text-primary-950 text-medium",
  },
  fieldLabel: "text-primary-950 font-medium",
  formField:
    "bg-white border rounded-lg border-secondary-300 h-12 px-3 text-primary-950 tracking-wide",
  formSections: "flex flex-col gap-8",
  formSectionContainer: "flex flex-col gap-4",
  labelAndField: "flex flex-col gap-y-2",
  modalStyle: adminModalStyle,
  modal: {
    heading: "font-bold text-xl text-center text-primary-950",
  },
  modalBodyStyle: "sm:min-w-[30rem] p-4 md:p-6 font-montserrat",
  inlineFields: "flex flex-row gap-4",
  itemBorder: "border border-solid rounded-lg shadow-md",
  screenPaddingX: "px-4 md:px-10",
  screenPaddingY: "py-8",
};

export function formFieldStyle(themeArea: ThemeArea | undefined) {
  let className =
    themeArea === "admin" ? ADMIN_STYLE.formField : Style.inputClass;
  className = clsx(className, "disabled:bg-secondary-100");
  return className;
}

// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { TextFormat1, TextFormat2 } from "types/content";
import MarkdownContent from "./MarkdownContent";
import TextToHtml from "./ui/TextToHtml";
interface Props {
  text: string;
  contentType?: "text/plain" | "text/markdown" | "text/html";
  format?: TextFormat1;
  formatUpper?: TextFormat2;
}
export default function FormattedText(props: Props) {
  let {
    contentType,
    format,
    formatUpper,
    text
  } = props;
  if (format && !formatUpper) {
    formatUpper = (format.toUpperCase() as TextFormat2);
  }
  if (contentType === "text/markdown") {
    formatUpper = "MARKDOWN";
  } else if (contentType === "text/html") {
    formatUpper = "HTML";
  } else if (contentType === "text/plain") {
    formatUpper = "PLAIN";
  }
  formatUpper = formatUpper || "MARKDOWN";
  if (formatUpper === "MARKDOWN") {
    return <MarkdownContent content={text} />;
  } else if (formatUpper === "HTML") {
    return <span className="external-content" dangerouslySetInnerHTML={{
      __html: text
    }} />;
  }
  return <span className="external-content" data-sentry-component="FormattedText" data-sentry-source-file="FormattedText.tsx">
      <TextToHtml text={text} data-sentry-element="TextToHtml" data-sentry-source-file="FormattedText.tsx" />
    </span>;
}
import { observer } from "mobx-react-lite";
import { ThemeArea } from "types/theme";
type Item = {
  id: string;
  name: string;
};
interface Props {
  item: Item;
  currentId?: string;
  onClick?: (item: any) => void;
  mapItem?: (item: any) => React.ReactNode;
  themeArea?: ThemeArea;
}
function ResultItem(props: Props) {
  const {
    item,
    currentId,
    themeArea
  } = props;
  let highlightClass = "bg-[var(--group-accent-ultralight)]";
  if (themeArea === "admin") {
    highlightClass = "bg-gf-green-light";
  }
  const selectedClass = item.id === currentId && highlightClass;
  function onClick(e: any) {
    e.preventDefault();
    props.onClick && props.onClick(item);
  }
  function mapItem(item: Item) {
    if (props.mapItem) {
      return props.mapItem(item);
    }
    return item.name;
  }
  return <div className={`${selectedClass} hover:${highlightClass} cursor-pointer py-1 px-2`} onMouseDown={onClick} data-sentry-component="ResultItem" data-sentry-source-file="ResultItem.tsx">
      {mapItem(item)}
    </div>;
}
export default observer(ResultItem);
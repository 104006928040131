const ROUTES = {
  "/": {
    "name": "HomePage1919f1cc04a849d4A64d52056612c12c",
    "type": "HTML",
    "key": "home-page"
  },
  "/about": {
    "name": "AboutUsPage31e6d138756b43cbB94aF56d174387d0",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/apply/*": {
    "name": "ApplicationPagesF95489180a414baf817fF844de4b5768",
    "type": "REFERENCE",
    "key": "application-pages"
  },
  "/events/public": {
    "name": "PublicEventsPageB76a3e134b3a4766802576ef2fba66d6",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageF4ea8e04Cfb94a07887aE32368621940",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesCd030d72Bcdb473993cf523fdc711047",
    "type": "HTML",
    "key": "member-pages"
  }
};

export default ROUTES;
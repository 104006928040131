import { SyntheticEvent } from "react";
import ActionButton from "./ActionButton";
import HollowButton from "./HollowButton";
import clsx from "clsx";
interface Props {
  buttonsClass?: string;
  cancel?: {
    onClick?: () => void;
    href?: string;
    disabled?: boolean;
  };
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  marginTopClass?: string;
  loading?: boolean;
  submitButton?: Partial<{
    handleClick: (e: SyntheticEvent) => void;
    label?: string;
    type: "button" | "submit";
    widthClass?: string;
  }>;
  withGroupFlow?: boolean;
}
export default function SubmitButtonRow(props: Props) {
  const {
    cancel,
    children,
    className,
    disabled,
    loading,
    submitButton,
    withGroupFlow
  } = props;
  const marginTopClass = props.marginTopClass ?? "mt-4";
  const buttonsClass = clsx("font-semibold", props.buttonsClass);
  const handleClick = cancel?.onClick;
  return <div className={clsx("flex gap-4 justify-between relative", marginTopClass, className)} data-sentry-component="SubmitButtonRow" data-sentry-source-file="SubmitButtonRow.tsx">
      {cancel ? <HollowButton onClick={handleClick} href={cancel.href} className={buttonsClass} disabled={cancel.disabled}>
          Cancel
        </HollowButton> : <div />}
      <ActionButton type={submitButton?.type || "submit"} disabled={disabled || false} onClick={submitButton?.handleClick} className={buttonsClass} widthClass={submitButton?.widthClass} size="large" loading={loading} withGroupFlow={withGroupFlow} data-sentry-element="ActionButton" data-sentry-source-file="SubmitButtonRow.tsx">
        {submitButton?.label || children || "Save"}
      </ActionButton>
    </div>;
}
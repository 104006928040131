import { useContentEditStore } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
import NavEdit from "./NavEdit";
function NavManagement() {
  const contentEditStore = useContentEditStore();
  const navItem = contentEditStore?.currentNavItem();
  const mode = navItem?.baseTypeEditMode || "search";
  return <div style={{
    maxWidth: "500px"
  }} className="mx-auto" data-sentry-component="NavManagement" data-sentry-source-file="NavManagement.tsx">
      {["edit", "add", "list"].includes(mode) && <NavEdit />}
    </div>;
}
export default observer(NavManagement);
// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { Account, AccountParams, AccountParams2, Me, PasswordInfo, SubscriptionParams, SubscriptionSettings } from "types/member";
import { Attendee, AttendeeParams, AttendeesAnnouncement, AttendeesAnnouncementParams, AttendeesAnnouncementParamsForCreate, AttendeesListParams, EventDetail, EventImage, EventSubmission, Participation, ParticipationBulkParams, RsvpParams, Venue } from "types/event";
import { Message, Conversation } from "types/messaging";
import { ApiCall, ApiResponse, Pagination } from "types/api";
import { parseDate } from "lib/gf-api/apiDecode";
import { fetchAndLog, wrapApiResponse, OldApiError, gfApiHeaders } from "lib/gf-api/api-util";
import InvalidSessionError from "./InvalidSessionError";
import { SimpleImageUpload } from "types/photo";
import { parseAttendee, decodeEventDetail, parseEventSubmission, parseParticipation } from "lib/gf-api/event-util";
import { gql } from "@apollo/client";
import { LIST_MEMBERS_FIELDS } from "./member-api";
import { UploadParams } from "types/upload";
export interface LoginSuccessResponse {
  status: "ok";
  account: Account;
  accessToken: string;
}
export interface LoginFailedResponse {
  status: "failed";
  error: string;
}
export type LoginResponse = LoginSuccessResponse | LoginFailedResponse;
export interface GetMembersParams {
  excludeMemberIds?: string[];
  jwt: string;
  page?: number;
  query: string;
}
export interface GetEventImagesParams {
  page?: number;
  query: string;
}
export interface GetEventImagesResult {
  images: EventImage[];
  pagination: Pagination;
}
export interface GetVenuesParams {
  page?: number;
  query: string;
}
export interface GetVenuesResult {
  venues: Venue[];
  pagination: Pagination;
}
export async function getMyself(call: ApiCall): Promise<Me | null> {
  const endpointUrl = call.baseUrl + "/accounts/me";
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call)
  });
  if (response.status === 401 || response.status === 403) {
    console.warn("getMyself(): InvalidSession");
    throw new InvalidSessionError(`http status: ${response.status}`);
  }
  const data = await response.json();
  if (data.data) {
    return parseMeResult(data.data);
  } else {
    return null;
  }
}
function parseMeResult(data: any): Me {
  const {
    account
  } = data;
  const duesPaidOn = parseDate(account.duesPaidOn);
  const emailConfirmedAt = parseDate(account.emailConfirmedAt);
  const joinedGroupOn = parseDate(account.joinedGroupOn);
  const membershipEndsOn = parseDate(account.membershipEndsOn);
  const trialEndsOn = parseDate(account.trialEndsOn);
  return {
    ...data,
    account: {
      ...account,
      duesPaidOn,
      emailConfirmedAt,
      joinedGroupOn,
      membershipEndsOn,
      trialEndsOn
    }
  };
}
export async function getCurrentAccount(call: ApiCall): Promise<ApiResponse<Account>> {
  const endpointUrl = call.baseUrl + "/accounts/self";
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call),
    method: "GET"
  });
  return wrapApiResponse(response, parseAccount);
}
export async function updateCurrentAccount(call: ApiCall, params: Partial<AccountParams>): Promise<ApiResponse<Account>> {
  const endpointUrl = call.baseUrl + "/accounts/self";
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call),
    body: JSON.stringify(params),
    method: "PATCH"
  });
  return wrapApiResponse(response, parseAccount);
}
function parseAccount(account: any): Account {
  const duesPaidOn = parseDate(account.duesPaidOn);
  const emailConfirmedAt = parseDate(account.emailConfirmedAt);
  const joinedGroupOn = parseDate(account.joinedGroupOn);
  const membershipEndsOn = parseDate(account.membershipEndsOn);
  const trialEndsOn = parseDate(account.trialEndsOn);
  return {
    ...account,
    duesPaidOn,
    emailConfirmedAt,
    joinedGroupOn,
    membershipEndsOn,
    trialEndsOn
  };
}
export async function updateAccountCredentials(call: ApiCall, params: Partial<AccountParams2>): Promise<ApiResponse<PasswordInfo>> {
  const endpointUrl = call.baseUrl + "/accounts/self_creds";
  const response = await fetchAndLog(endpointUrl, {
    body: JSON.stringify(params),
    headers: gfApiHeaders(call),
    method: "PATCH"
  });
  return wrapApiResponse(response);
}
export async function postPasswordReset(call: ApiCall, params: {
  email: string;
  source?: string;
  webBaseUrl?: string;
}): Promise<any> {
  let response;
  response = await fetchAndLog(call.baseUrl + "/accounts/password_resets", {
    body: JSON.stringify(params),
    headers: gfApiHeaders(call),
    method: "POST"
  });
  const result = await handleOldStyleResponse(response);
  if (result instanceof OldApiError) {
    throw result;
  }
  return result;
}
export async function postPasswordChange(call: ApiCall, {
  password
}: {
  password: string;
}): Promise<ApiResponse<PasswordInfo>> {
  const response = await fetchAndLog(call.baseUrl + "/accounts/change_password", {
    body: JSON.stringify({
      password
    }),
    headers: gfApiHeaders(call),
    method: "POST"
  });
  return wrapApiResponse(response, parsePasswordInfo);
}
function parsePasswordInfo(data: any): PasswordInfo {
  return (data as PasswordInfo);
}
interface UploadResponse {
  url: string;
  photoId: string;
}
export async function postUpload(call: ApiCall, {
  file,
  cropBox,
  rotationAmount,
  uploadType
}: UploadParams): Promise<UploadResponse> {
  const {
    baseUrl,
    jwt
  } = call;
  const fd = new window.FormData();
  fd.append("file", file);
  if (cropBox) {
    fd.append("crop[left]", `${cropBox.left}`);
    fd.append("crop[top]", `${cropBox.top}`);
    fd.append("crop[right]", `${cropBox.right}`);
    fd.append("crop[bottom]", `${cropBox.bottom}`);
  }
  if (rotationAmount) {
    fd.append("rotation", `${rotationAmount}`);
  }
  if (uploadType) {
    fd.append("uploadType", uploadType);
  }
  const uri = baseUrl + "/uploads";
  const xhr = new window.XMLHttpRequest();
  return new Promise(function (resolve, reject) {
    xhr.open("POST", uri, true);
    xhr.setRequestHeader("Authorization", `Bearer ${jwt}`);
    xhr.setRequestHeader("gf-group", call.groupSlug);
    xhr.setRequestHeader("gf-org", call.groupSlug);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const body = JSON.parse(xhr.responseText);
          resolve(body);
        } else {
          reject(new OldApiError("Network Error", null, []));
        }
      }
    };

    // Initiate a multipart/form-data upload
    xhr.send(fd);
  });
}
export async function postEventImage(call: ApiCall, file: File): Promise<ApiResponse<SimpleImageUpload>> {
  const url = call.baseUrl + "/events/images";
  return postImage(url, file, call);
}
export async function postContentPhoto(call: ApiCall, file: File): Promise<ApiResponse<SimpleImageUpload>> {
  const url = call.baseUrl + "/content/photos";
  return postImage(url, file, call);
}
export async function postImage(url: string, file: File, call: ApiCall): Promise<ApiResponse<SimpleImageUpload>> {
  const fd = new window.FormData();
  fd.append("file", file);
  const xhr = new window.XMLHttpRequest();
  return new Promise(function (resolve) {
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Authorization", `Bearer ${call.jwt}`);
    xhr.setRequestHeader("gf-org", call.groupSlug);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        let body: any = {};
        try {
          body = JSON.parse(xhr.responseText);
        } catch {}
        if (xhr.status >= 200 && xhr.status <= 299) {
          resolve({
            error: false,
            data: body.data
          });
        } else {
          const serverError = xhr.status >= 500 && xhr.status <= 599;
          const requestErrors = body.errors ? [{
            title: "There was a problem processing the image upload"
          }] : [];
          resolve({
            error: true,
            status: xhr.status,
            serverError,
            requestErrors
          });
        }
      }
    };

    // Initiate a multipart/form-data upload
    xhr.send(fd);
  });
}
async function handleOldStyleResponse<T>(response: any, parseBody?: (data: any) => T): Promise<T | OldApiError> {
  const {
    status
  } = response;
  const body = await response.json();
  if (status >= 200 && status < 300) {
    if (parseBody) {
      return parseBody(body);
    } else {
      return body;
    }
  }
  const {
    errors
  } = body;
  let error;
  if (status >= 400 && status < 500) {
    error = new OldApiError("Bad Request", status, errors);
  } else if (status >= 500 && status < 600) {
    error = new OldApiError("Server Error", status, errors);
  } else {
    error = new OldApiError("Unexpected HTTP Status", status, []);
  }
  return error;
}
export async function getEventImages(call: ApiCall, params: GetEventImagesParams): Promise<GetEventImagesResult> {
  const searchParams = new URLSearchParams();
  searchParams.append("q", params.query);
  searchParams.append("page", `${params.page || 1}`);
  searchParams.append("pageSize", "20");
  const endpointUrl = `${call.baseUrl}/events/images/?${searchParams.toString()}`;
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call)
  });
  if (response.status !== 200) {
    const data = await response.json();
    console.warn("response body", data);
    throw new Error(`Unexpected HTTP status for response: ${response.status}`);
  }
  const body = await response.json();
  return {
    images: (body.data as EventImage[]),
    pagination: (body.meta as Pagination)
  };
}
export async function getVenues(call: ApiCall, params: GetVenuesParams): Promise<GetVenuesResult> {
  const searchParams = new URLSearchParams();
  searchParams.append("q", params.query);
  const page = params.page || 1;
  searchParams.append("page", `${page}`);
  searchParams.append("pageSize", "20");
  const endpointUrl = `${call.baseUrl}/venues?${searchParams.toString()}`;
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call)
  });
  if (response.status !== 200) {
    const data = await response.json();
    console.warn("response body", data);
    throw new Error(`Unexpected HTTP status for response: ${response.status}`);
  }
  const body = await response.json();
  return {
    venues: body.data,
    pagination: (body.meta as Pagination)
  };
}
export async function upsertEvent(call: ApiCall, event: Partial<EventSubmission>): Promise<ApiResponse<EventSubmission>> {
  const method = event.id ? "PATCH" : "POST";
  const {
    id,
    ...params
  } = event;
  const path = id ? `/event_submissions/${id}` : "/event_submissions";
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    method,
    headers: gfApiHeaders(call),
    body: JSON.stringify({
      data: params
    })
  });
  return wrapApiResponse(response, parseEventSubmission);
}
export async function getEventSubmission(call: ApiCall, id: string): Promise<ApiResponse<EventSubmission>> {
  const response = await fetchAndLog(call.baseUrl + `/event_submissions/${id}`, {
    headers: gfApiHeaders(call)
  });
  return wrapApiResponse(response, parseEventSubmission);
}
export async function getEventSubmissionFromEvent(call: ApiCall, id: string): Promise<ApiResponse<EventSubmission>> {
  const response = await fetchAndLog(call.baseUrl + `/event_submissions/from_event/${id}`, {
    headers: gfApiHeaders(call)
  });
  return wrapApiResponse(response, parseEventSubmission);
}
export async function getEvent(call: ApiCall, {
  id
}: {
  id: string;
}): Promise<ApiResponse<EventDetail>> {
  const {
    baseUrl,
    jwt
  } = call;
  const path = `/events/${id}?signedIn=${jwt ? "true" : "false"}`;
  const endpointUrl = baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call)
  });
  return wrapApiResponse(response, decodeEventDetail);
}
export async function listAttendees(call: ApiCall, {
  id,
  params
}: {
  id: string;
  params?: Partial<AttendeesListParams>;
}): Promise<ApiResponse<Attendee[]>> {
  const searchParams = buildAttendeesListQueryParams(params);
  const path = `/events/${id}/attendees?${searchParams.toString()}`;
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call)
  });
  return wrapApiResponse(response, attendees => {
    return attendees.map(parseAttendee);
  });
}
export const LIST_ATTENDEES = gql`
  query ListAttendees(
    $filter: Participation2FilterInput
    $sort: [Participation2SortInput]
  ) {
    listAttendees(filter: $filter, sort: $sort, offset: 0, limit: 200) {
      hasNextPage
      results {
        id
        member {
          ${LIST_MEMBERS_FIELDS}
        }
      }
    }
  }
`;
function buildAttendeesListQueryParams(params?: Partial<AttendeesListParams>) {
  const searchParams = new URLSearchParams();
  if (params?.waitlisted) {
    searchParams.append("waitlisted", params.waitlisted);
  }
  if (params?.sortBy) {
    searchParams.append("sortBy", params.sortBy);
  }
  if (params?.paid) {
    searchParams.append("paid", params.paid);
  }
  if (params?.rsvpReply) {
    searchParams.append("rsvpReply", params.rsvpReply);
  }
  if (params?.query) {
    searchParams.append("query", params.query);
  }
  if (params?.role) {
    searchParams.append("role", params.role);
  }
  if (params?.status) {
    searchParams.append("status", params.status);
  }
  return searchParams;
}
export async function exportAttendees(call: ApiCall, {
  id,
  params
}: {
  id: string;
  params?: Partial<AttendeesListParams>;
}): Promise<ApiResponse<string>> {
  const searchParams = buildAttendeesListQueryParams(params);
  const path = `/events/${id}/attendees.csv?${searchParams.toString()}`;
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call)
  });
  const body = await response.text();
  if (response.status >= 200 && response.status < 300) {
    return {
      data: body,
      error: false
    };
  } else {
    return {
      error: true,
      status: response.status,
      serverError: response.status >= 500 && response.status < 600,
      requestErrors: [{
        title: "Error",
        detail: body
      }]
    };
  }
}
export async function createAttendee(call: ApiCall, attendee: Partial<AttendeeParams>): Promise<ApiResponse<Attendee>> {
  const {
    eventId,
    ...params
  } = attendee;
  const path = `/events/${eventId}/attendees`;
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    method: "POST",
    headers: gfApiHeaders(call),
    body: JSON.stringify(params)
  });
  return wrapApiResponse(response, parseAttendee);
}
export async function updateAttendee(call: ApiCall, attendee: Partial<AttendeeParams>): Promise<ApiResponse<Attendee>> {
  const {
    eventId,
    memberId,
    ...params
  } = attendee;
  const userId = memberId;
  const path = `/events/${eventId}/attendees/${userId}`;
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    method: "PATCH",
    headers: gfApiHeaders(call),
    body: JSON.stringify(params)
  });
  return wrapApiResponse(response, parseAttendee);
}
export async function bulkUpdateAttendees(call: ApiCall, fnParams: {
  eventId: string;
  updates: Partial<ParticipationBulkParams>[];
}): Promise<ApiResponse<Attendee>> {
  const {
    eventId,
    updates
  } = fnParams;
  const path = `/events/${eventId}/attendees/bulk`;
  const endpointUrl = call.baseUrl + path;
  const params = {
    data: updates
  };
  const response = await fetchAndLog(endpointUrl, {
    method: "PUT",
    headers: gfApiHeaders(call),
    body: JSON.stringify(params)
  });
  return wrapApiResponse(response, value => value);
}
export async function rsvp(call: ApiCall, rsvpParams1: Partial<RsvpParams>): Promise<ApiResponse<Participation>> {
  const {
    eventId,
    ...rsvpParams
  } = rsvpParams1;
  const path = `/events/${eventId}/attendees/self`;
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    method: "PATCH",
    body: JSON.stringify(rsvpParams),
    headers: gfApiHeaders(call)
  });
  return wrapApiResponse(response, parseParticipation);
}
export function parseConversation(data: any): Conversation {
  let {
    mostRecentReceivedAt
  } = data;
  mostRecentReceivedAt = parseDate(mostRecentReceivedAt);
  let lastReadMessage = data.lastReadMessage;
  if (lastReadMessage) {
    lastReadMessage = parseMessage(lastReadMessage);
  }
  return {
    ...data,
    mostRecentReceivedAt,
    lastReadMessage
  };
}
export function parseMessage(data: any): Message {
  const sentAt = parseDate(data.sendAt);
  return {
    ...data,
    sentAt
  };
}
export async function createAttendeesAnnouncement(call: ApiCall, announcement: AttendeesAnnouncementParams): Promise<ApiResponse<AttendeesAnnouncement>> {
  const {
    eventId,
    ...params
  } = announcement;
  const path = `/events/${eventId}/announcements`;
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    method: "POST",
    headers: gfApiHeaders(call),
    body: JSON.stringify(params)
  });
  return wrapApiResponse(response, parseAttendeesAnnouncement);
}
export async function updateAttendeesAnnouncement(call: ApiCall, announcement: AttendeesAnnouncementParamsForCreate): Promise<ApiResponse<AttendeesAnnouncement>> {
  const {
    eventId,
    id,
    ...params
  } = announcement;
  const path = `/events/${eventId}/announcements/${id}`;
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    method: "PATCH",
    headers: gfApiHeaders(call),
    body: JSON.stringify(params)
  });
  return wrapApiResponse(response, parseAttendeesAnnouncement);
}
export async function getAttendeesAnnouncement(call: ApiCall, fnParams: {
  eventId: string;
  id: string;
}): Promise<ApiResponse<AttendeesAnnouncement>> {
  const {
    eventId,
    id
  } = fnParams;
  const path = `/events/${eventId}/announcements/${id}`;
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call)
  });
  return wrapApiResponse(response, parseAttendeesAnnouncement);
}
function parseAttendeesAnnouncement(data: any) {
  const createdAt = parseDate(data.createdAt);
  const publishedAt = parseDate(data.publishedAt);
  const updatedAt = parseDate(data.updatedAt);
  return {
    ...data,
    createdAt,
    publishedAt,
    updatedAt
  };
}
export async function getSubscriptionSettings(call: ApiCall): Promise<ApiResponse<SubscriptionSettings>> {
  const path = "/subscriptions/self";
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    headers: gfApiHeaders(call)
  });
  return wrapApiResponse(response);
}
export async function saveSubscriptionSettings(call: ApiCall, settings: SubscriptionParams): Promise<ApiResponse<SubscriptionSettings>> {
  const path = "/subscriptions/self";
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    method: "PATCH",
    headers: gfApiHeaders(call),
    body: JSON.stringify(settings)
  });
  return wrapApiResponse(response);
}
export async function postSimulateUnregistered(call: ApiCall, memberId: string): Promise<ApiResponse<any>> {
  const path = "/testing/simulate_unregistered";
  const endpointUrl = call.baseUrl + path + `?memberId=${memberId}`;
  const response = await fetchAndLog(endpointUrl, {
    method: "POST",
    credentials: "include"
  });
  return wrapApiResponse(response);
}
export async function postEmailConfirmation(call: ApiCall, token: string): Promise<ApiResponse<any>> {
  const path = "/accounts/confirm_email";
  const endpointUrl = call.baseUrl + path;
  const response = await fetchAndLog(endpointUrl, {
    method: "POST",
    headers: gfApiHeaders(call),
    body: JSON.stringify({
      token
    }),
    credentials: "include"
  });
  return wrapApiResponse(response);
}
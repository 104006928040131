// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ReactModal from "react-modal";
import { ApiErrorState } from "types/api";
import { ActionButton } from "./form";
interface Props {
  apiError: ApiErrorState;
  onClose?: () => void;
}
export default function GeneralApiError(props: Props) {
  const {
    apiError,
    onClose
  } = props;
  function close() {
    onClose && onClose();
  }
  return <ReactModal isOpen={Boolean(apiError)} onRequestClose={close} data-sentry-element="ReactModal" data-sentry-component="GeneralApiError" data-sentry-source-file="GeneralApiError.tsx">
      {apiError && <>
          <p className="p mt-0">An error occurred.</p>
          <p className="p">{apiError.explanation}</p>
          <div>
            <ActionButton onClick={close}>Ok</ActionButton>
          </div>
        </>}
    </ReactModal>;
}
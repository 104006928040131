import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "d89c178b-10f8-461b-813a-ad4878071bf0"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "31e6d138-756b-43cb-b94a-f56d174387d0"
export function FooterD89c178b10f8461b813aAd4878071bf0(props: any) {
  return (
    <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="FooterD89c178b10f8461b813aAd4878071bf0" {...props} />
  );
}

// id: "834b0d46-6a9a-4d8b-ab75-a3db89204e19"
// title: ""
// type: :text
// key: "title"
// parent_id: "20044cc4-71c7-4410-9056-e2d0d4421d55"
export const Title834b0d466a9a4d8bAb75A3db89204e19 = null;

// id: "3977ece9-7988-4a97-8c89-10af77797711"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader3977ece979884a978c8910af77797711(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader3977ece979884a978c8910af77797711 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a parentTag="HeaderTop318712f55fe94a33B32b77681093bf75" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42 parentTag="HeroD5b7f61a92ed48eb92aa3b8e117f581c" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "85be3607-3549-43aa-b96a-d77baa28d19b"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage85be3607354943aaB96aD77baa28d19b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage85be3607354943aaB96aD77baa28d19b`}>
      {props["header"] || <SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="HeaderCa64e9240eb6431c92ea2d3457ed7a7f" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="FooterBf48e9ce86144cc09d526a50c37f3e26" {...props} />}
    </div>
  );
}

// id: "60cfe84d-c803-4879-a4b3-e823ff2fefef"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero60cfe84dC8034879A4b3E823ff2fefef(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero60cfe84dC8034879A4b3E823ff2fefef hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button className="Button407ae04966544d25B2feAe41e7db6e9b" parentTag={buildClassName("Button407ae04966544d25B2feAe41e7db6e9b", parentTag)} label="Join Us" href="/join" {...props} />}
      </div>
    </div>
  );
}

// id: "b904d446-4039-4cd4-b41b-7be20fed9b17"
// title: ""
// type: :text
// key: "title"
// parent_id: "b9768ed0-a60b-467f-96fd-fad263a63d42"
export const TitleB904d44640394cd4B41b7be20fed9b17 = "Default Title";

// id: "3775cfa9-29ea-40f8-a610-77da0a33d6d1"
// title: ""
// type: :text
// key: "title"
// parent_id: "3977ece9-7988-4a97-8c89-10af77797711"
export const Title3775cfa929ea40f8A61077da0a33d6d1 = "Secondary Marketing Title 1";

// id: "4ce0202f-71c9-404c-beb9-d46fe30ad868"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "badcdfaa-842f-46ad-92d1-becc628605f1"
export function HeaderTop4ce0202f71c9404cBeb9D46fe30ad868(props: any) {
  return (
    <DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a parentTag="HeaderTop4ce0202f71c9404cBeb9D46fe30ad868" {...props} />
  );
}

// id: "a0c5f323-60db-4714-a323-57689b12d404"
// title: ""
// type: :text
// key: "title"
// parent_id: "d5b7f61a-92ed-48eb-92aa-3b8e117f581c"
export const TitleA0c5f32360db4714A32357689b12d404 = "Secondary Marketing Title 2";

// id: "8c185371-f5e0-4bcd-ba34-0f36fc317e5f"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "cc9ab065-ed79-46bf-9f46-f071e0ee5535"
export const ContentSlug8c185371F5e04bcdBa340f36fc317e5f = "header-logo";

// id: "1aa3b511-d6bb-49e1-be5b-e653345da47a"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a(props: any) {
  const Link = AppStore.presets["Link"];
  const Image = AppStore.presets["Image"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a header-top`}>
      {props["linked-logo"] || <Link className="LinkedLogo949542aa2cc84cfbAe9770641f10a637" parentTag={buildClassName("LinkedLogo949542aa2cc84cfbAe9770641f10a637", parentTag)} className="linked-logo" url="/" label={<Image className="LabelCc9ab065Ed7946bf9f46F071e0ee5535" parentTag={buildClassName("LabelCc9ab065Ed7946bf9f46F071e0ee5535", parentTag)} contentSlug="header-logo" {...props} />} {...props} />}
      {props["navigation"] || <PrimaryNavigation className="NavigationB3387485Aba1472d976d39ca49982d63" parentTag={buildClassName("NavigationB3387485Aba1472d976d39ca49982d63", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "3c4c61ad-f037-4dff-9971-ba28f42e3862"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label3c4c61adF0374dff9971Ba28f42e3862(props: any) {
  return (
    <DefaultLogo5124ace5621e4b53B391D6c5a1807f14 parentTag="Label3c4c61adF0374dff9971Ba28f42e3862" {...props} />
  );
}

// id: "9174296c-d308-45c4-be3d-ef3bffb26e4e"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader9174296cD30845c4Be3dEf3bffb26e4e(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <header className={`${parentTag || ""} ShortHeader9174296cD30845c4Be3dEf3bffb26e4e`}>
      {props["linked-logo"] || <Link className="LinkedLogoDdeb278655c44e0e81a7Ee4e45856eec" parentTag={buildClassName("LinkedLogoDdeb278655c44e0e81a7Ee4e45856eec", parentTag)} label={<DefaultLogo5124ace5621e4b53B391D6c5a1807f14 parentTag="Label8a892fb640bd4e57A6ab7040a53e5b3d" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation className="Navigation2615192158e9460a9674Cce7e2b3299f" parentTag={buildClassName("Navigation2615192158e9460a9674Cce7e2b3299f", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
    </header>
  );
}

// id: "58d38ef9-c841-494b-9a30-5e1dc92f929c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "cf408b53-7369-42b2-911e-6f48e17451fb"
export function Footer58d38ef9C841494b9a305e1dc92f929c(props: any) {
  return (
    <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="Footer58d38ef9C841494b9a305e1dc92f929c" {...props} />
  );
}

// id: "9bad529a-27f9-459c-b4de-8ee7ed972e51"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "9174296c-d308-45c4-be3d-ef3bffb26e4e"
export const BackgroundImage9bad529a27f9459cB4de8ee7ed972e51 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "26151921-58e9-460a-9674-cce7e2b3299f"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "9174296c-d308-45c4-be3d-ef3bffb26e4e"
export function Navigation2615192158e9460a9674Cce7e2b3299f(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation className="Navigation2615192158e9460a9674Cce7e2b3299f" parentTag={buildClassName("Navigation2615192158e9460a9674Cce7e2b3299f", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "badcdfaa-842f-46ad-92d1-becc628605f1"
// title: "Home Header Wrapper"
// type: :html
// key: "header-wrapper"
// parent_id: "1919f1cc-04a8-49d4-a64d-52056612c12c"
export function HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a parentTag="HeaderTop4ce0202f71c9404cBeb9D46fe30ad868" {...props} />}
      </div>
      {props["hero-wrapper"] || <HeroWrapper90ce60c0Bdad4aafB1a6923a63568690 />}
    </div>
  );
}

// id: "8f63071a-30f7-49d5-852f-1fb0c296bde2"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "97367487-bcc7-4349-91e2-54eff4b74849"
export const NavMenuSlug8f63071a30f749d5852f1fb0c296bde2 = "members-primary-nav";

// id: "aba12e11-2e99-40ef-9c19-93f4f7233f56"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterAba12e112e9940ef9c1993f4f7233f56(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const Image = AppStore.presets["Image"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterAba12e112e9940ef9c1993f4f7233f56 footer`}>
      {props["links"] || <Navigation className="Links624d74d01b4447b593ba1c394b5d5e5a" parentTag={buildClassName("Links624d74d01b4447b593ba1c394b5d5e5a", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Image className="LogoDa21147cD28b4623B4cb9eb333a09dfa" parentTag={buildClassName("LogoDa21147cD28b4623B4cb9eb333a09dfa", parentTag)} contentSlug="footer-logo" {...props} />}
      </div>
      {props["copyright"] || <GroupCopyright className="Copyright47177e5904a940abA20d67f04b7e0b8c" parentTag={buildClassName("Copyright47177e5904a940abA20d67f04b7e0b8c", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag className="Groupflow38cc4b57776144098ba6Ca89e0f87d5a" parentTag={buildClassName("Groupflow38cc4b57776144098ba6Ca89e0f87d5a", parentTag)} {...props} />}
    </div>
  );
}

// id: "5124ace5-621e-4b53-b391-d6c5a1807f14"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo5124ace5621e4b53B391D6c5a1807f14(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogo5124ace5621e4b53B391D6c5a1807f14 logo`} alt="logo" />
  );
}

// id: "af17238e-1f91-49bf-931c-153d55fb999d"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleAf17238e1f9149bf931c153d55fb999d = "Leveling";

// id: "071cea74-f9e8-4794-9f6b-951fbe480b76"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote071cea74F9e847949f6b951fbe480b76(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote071cea74F9e847949f6b951fbe480b76`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "848b09bc-f75a-4e38-9181-e4ac247ad201"
// title: "Home Main"
// type: :html
// key: "main"
// parent_id: "1919f1cc-04a8-49d4-a64d-52056612c12c"
export function Main848b09bcF75a4e389181E4ac247ad201(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} Main848b09bcF75a4e389181E4ac247ad201`}>
      <div className="cards-wrapper">
        {props["cards"] || <MarketingCards className="CardsB98a3e4d52584f4c884b307b7f4c5c62" parentTag={buildClassName("CardsB98a3e4d52584f4c884b307b7f4c5c62", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-cards" {...props} />}
      </div>
    </main>
  );
}

// id: "ca64e924-0eb6-431c-92ea-2d3457ed7a7f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "85be3607-3549-43aa-b96a-d77baa28d19b"
export function HeaderCa64e9240eb6431c92ea2d3457ed7a7f(props: any) {
  return (
    <SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="HeaderCa64e9240eb6431c92ea2d3457ed7a7f" title="Secondary Page" {...props} />
  );
}

// id: "12f87131-201c-4579-9113-808092593756"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b76a3e13-4b3a-4766-8025-76ef2fba66d6"
export function Header12f87131201c45799113808092593756(props: any) {
  return (
    <SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="Header12f87131201c45799113808092593756" title="Public Events" {...props} />
  );
}

// id: "97367487-bcc7-4349-91e2-54eff4b74849"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "8d6594ab-181a-4e14-b53c-b6befe24c535"
export function HeaderTop97367487Bcc7434991e254eff4b74849(props: any) {
  return (
    <DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a parentTag="HeaderTop97367487Bcc7434991e254eff4b74849" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "b98a3e4d-5258-4f4c-884b-307b7f4c5c62"
// title: "Home Cards"
// type: :gf_preset
// key: "cards"
// parent_id: "848b09bc-f75a-4e38-9181-e4ac247ad201"
export function CardsB98a3e4d52584f4c884b307b7f4c5c62(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards className="CardsB98a3e4d52584f4c884b307b7f4c5c62" parentTag={buildClassName("CardsB98a3e4d52584f4c884b307b7f4c5c62", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-cards" {...props} />
  );
}

// id: "987fb541-1355-43b2-808f-5476b11a03a7"
// title: ""
// type: :text
// key: "title"
// parent_id: "12f87131-201c-4579-9113-808092593756"
export const Title987fb541135543b2808f5476b11a03a7 = "Public Events";

// id: "d92826d1-8d2f-47f4-92ac-e633e1ef2a03"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "b3387485-aba1-472d-976d-39ca49982d63"
export const NavMenuSlugD92826d18d2f47f492acE633e1ef2a03 = "marketing-primary-nav";

// id: "6ed8e8f0-de06-4624-8262-a7cafe610577"
// title: ""
// type: :gf_preset
// key: "hero"
// parent_id: "90ce60c0-bdad-4aaf-b1a6-923a63568690"
export function Hero6ed8e8f0De0646248262A7cafe610577(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Hero className="Hero6ed8e8f0De0646248262A7cafe610577" parentTag={buildClassName("Hero6ed8e8f0De0646248262A7cafe610577", parentTag)} postSlug="home-hero" {...props} />
  );
}

// id: "20044cc4-71c7-4410-9056-e2d0d4421d55"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader20044cc471c744109056E2d0d4421d55(props: any) {
  return (
    <ShortHeader9174296cD30845c4Be3dEf3bffb26e4e parentTag="DefaultHeader20044cc471c744109056E2d0d4421d55" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "f4ea8e04-cfb9-4a07-887a-e32368621940"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageF4ea8e04Cfb94a07887aE32368621940(props: any) {
  return (
    <SecondaryPage85be3607354943aaB96aD77baa28d19b parentTag="JoinUsPageF4ea8e04Cfb94a07887aE32368621940" header={<SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="HeaderD664f739Aba84000Bf53Feb092f3906a" title="Join Us" />} {...props} />
  );
}

// id: "624d74d0-1b44-47b5-93ba-1c394b5d5e5a"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "aba12e11-2e99-40ef-9c19-93f4f7233f56"
export function Links624d74d01b4447b593ba1c394b5d5e5a(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation className="Links624d74d01b4447b593ba1c394b5d5e5a" parentTag={buildClassName("Links624d74d01b4447b593ba1c394b5d5e5a", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "bf48e9ce-8614-4cc0-9d52-6a50c37f3e26"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "85be3607-3549-43aa-b96a-d77baa28d19b"
export function FooterBf48e9ce86144cc09d526a50c37f3e26(props: any) {
  return (
    <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="FooterBf48e9ce86144cc09d526a50c37f3e26" {...props} />
  );
}

// id: "d664f739-aba8-4000-bf53-feb092f3906a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f4ea8e04-cfb9-4a07-887a-e32368621940"
export function HeaderD664f739Aba84000Bf53Feb092f3906a(props: any) {
  return (
    <SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="HeaderD664f739Aba84000Bf53Feb092f3906a" title="Join Us" {...props} />
  );
}

// id: "86f5e3fe-bc45-43e2-a770-05abc1fc97da"
// title: ""
// type: :text
// key: "class"
// parent_id: "b3387485-aba1-472d-976d-39ca49982d63"
export const Class86f5e3feBc4543e2A77005abc1fc97da = "navigation";

// id: "9b897761-37e9-4a87-b76f-503ae60ca1d6"
// title: ""
// type: :text
// key: "title"
// parent_id: "205b1a64-2181-41aa-a51e-fef1ec9410c8"
export const Title9b89776137e94a87B76f503ae60ca1d6 = "Join Us";

// id: "8d6594ab-181a-4e14-b53c-b6befe24c535"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader8d6594ab181a4e14B53cB6befe24c535(props: any) {
  return (
    <ShortHeader9174296cD30845c4Be3dEf3bffb26e4e parentTag="MembersAreaHeader8d6594ab181a4e14B53cB6befe24c535" header-top={<DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a parentTag="HeaderTop97367487Bcc7434991e254eff4b74849" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "41caf18b-482e-4c6e-902f-3af664af9fa3"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href41caf18b482e4c6e902f3af664af9fa3 = "/";

// id: "d5b7f61a-92ed-48eb-92aa-3b8e117f581c"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "3977ece9-7988-4a97-8c89-10af77797711"
export function HeroD5b7f61a92ed48eb92aa3b8e117f581c(props: any) {
  return (
    <SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42 parentTag="HeroD5b7f61a92ed48eb92aa3b8e117f581c" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "949542aa-2cc8-4cfb-ae97-70641f10a637"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "1aa3b511-d6bb-49e1-be5b-e653345da47a"
export function LinkedLogo949542aa2cc84cfbAe9770641f10a637(props: any) {
  const Link = AppStore.presets["Link"];
  const Image = AppStore.presets["Image"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link className="LinkedLogo949542aa2cc84cfbAe9770641f10a637" parentTag={buildClassName("LinkedLogo949542aa2cc84cfbAe9770641f10a637", parentTag)} className="linked-logo" url="/" label={<Image className="LabelCc9ab065Ed7946bf9f46F071e0ee5535" parentTag={buildClassName("LabelCc9ab065Ed7946bf9f46F071e0ee5535", parentTag)} contentSlug="header-logo" {...props} />} {...props} />
  );
}

// id: "744b21a9-9cb9-4939-8053-09ad517658ad"
// title: ""
// type: :text
// key: "alt"
// parent_id: "5124ace5-621e-4b53-b391-d6c5a1807f14"
export const Alt744b21a99cb94939805309ad517658ad = "logo";

// id: "5f6e15bb-8e77-45a4-9403-6cdf4408fef6"
// title: ""
// type: :text
// key: "class"
// parent_id: "949542aa-2cc8-4cfb-ae97-70641f10a637"
export const Class5f6e15bb8e7745a494036cdf4408fef6 = "linked-logo";

// id: "27b3a30d-95f1-4c8f-9d2f-1cda17d1b6a9"
// title: ""
// type: :text
// key: "label"
// parent_id: "407ae049-6654-4d25-b2fe-ae41e7db6e9b"
export const Label27b3a30d95f14c8f9d2f1cda17d1b6a9 = "Join Us";

// id: "8a892fb6-40bd-4e57-a6ab-7040a53e5b3d"
// title: ""
// type: :reference
// key: "label"
// parent_id: "ddeb2786-55c4-4e0e-81a7-ee4e45856eec"
export function Label8a892fb640bd4e57A6ab7040a53e5b3d(props: any) {
  return (
    <DefaultLogo5124ace5621e4b53B391D6c5a1807f14 parentTag="Label8a892fb640bd4e57A6ab7040a53e5b3d" {...props} />
  );
}

// id: "7d79d5a9-f061-415e-b044-b1c2bb437d01"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "6ed8e8f0-de06-4624-8262-a7cafe610577"
export const PostSlug7d79d5a9F061415eB044B1c2bb437d01 = "home-hero";

// id: "31e6d138-756b-43cb-b94a-f56d174387d0"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage31e6d138756b43cbB94aF56d174387d0(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage31e6d138756b43cbB94aF56d174387d0 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="HeaderB8de342d95a446b4B10eAaf7432fb400" title="About Us" {...props} />}
      {props["main"] || <MainB68e6adfF2a64136823f975389e6c71a />}
      {props["footer"] || <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="FooterD89c178b10f8461b813aAd4878071bf0" {...props} />}
    </div>
  );
}

// id: "b8ac37ab-095c-4f7a-a9ec-5a9035ed674c"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterB8ac37ab095c4f7aA9ec5a9035ed674c(props: any) {
  return (
    <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="FooterB8ac37ab095c4f7aA9ec5a9035ed674c" {...props} />
  );
}

// id: "3af318d7-8f3d-4c23-bded-c1c8f2e2a665"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "cd030d72-bcdb-4739-93cf-523fdc711047"
export function Footer3af318d78f3d4c23BdedC1c8f2e2a665(props: any) {
  return (
    <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="Footer3af318d78f3d4c23BdedC1c8f2e2a665" {...props} />
  );
}

// id: "ceeb0011-9c1d-466f-89da-f6f941146bcc"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "cd030d72-bcdb-4739-93cf-523fdc711047"
export function SecondaryNavCeeb00119c1d466f89daF6f941146bcc(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation className="SecondaryNavCeeb00119c1d466f89daF6f941146bcc" parentTag={buildClassName("SecondaryNavCeeb00119c1d466f89daF6f941146bcc", parentTag)} {...props} />
  );
}

// id: "b3387485-aba1-472d-976d-39ca49982d63"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "1aa3b511-d6bb-49e1-be5b-e653345da47a"
export function NavigationB3387485Aba1472d976d39ca49982d63(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation className="NavigationB3387485Aba1472d976d39ca49982d63" parentTag={buildClassName("NavigationB3387485Aba1472d976d39ca49982d63", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "38cc4b57-7761-4409-8ba6-ca89e0f87d5a"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "aba12e11-2e99-40ef-9c19-93f4f7233f56"
export function Groupflow38cc4b57776144098ba6Ca89e0f87d5a(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag className="Groupflow38cc4b57776144098ba6Ca89e0f87d5a" parentTag={buildClassName("Groupflow38cc4b57776144098ba6Ca89e0f87d5a", parentTag)} {...props} />
  );
}

// id: "9cbfd509-004f-4a2b-b7ff-7d8e99f0554b"
// title: ""
// type: :gf_preset
// key: "cards"
// parent_id: "b68e6adf-f2a6-4136-823f-975389e6c71a"
export function Cards9cbfd509004f4a2bB7ff7d8e99f0554b(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards className="Cards9cbfd509004f4a2bB7ff7d8e99f0554b" parentTag={buildClassName("Cards9cbfd509004f4a2bB7ff7d8e99f0554b", parentTag)} postSlug="about-us-cards" {...props} />
  );
}

// id: "a6427e3c-26b7-4269-9ac4-c073cea30485"
// title: ""
// type: :text
// key: "url"
// parent_id: "949542aa-2cc8-4cfb-ae97-70641f10a637"
export const UrlA6427e3c26b742699ac4C073cea30485 = "/";

// id: "6d55e11b-802e-49eb-917b-73560a09ac15"
// title: ""
// type: :text
// key: "title"
// parent_id: "d664f739-aba8-4000-bf53-feb092f3906a"
export const Title6d55e11b802e49eb917b73560a09ac15 = "Join Us";

// id: "b76a3e13-4b3a-4766-8025-76ef2fba66d6"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageB76a3e134b3a4766802576ef2fba66d6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageB76a3e134b3a4766802576ef2fba66d6`}>
      {props["header"] || <SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="Header12f87131201c45799113808092593756" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="Footer8f0c4f143df14efb820b569541c3198a" {...props} />}
    </div>
  );
}

// id: "f9548918-0a41-4baf-817f-f844de4b5768"
// title: "Member Application Pages"
// type: :reference
// key: "application-pages"
// parent_id: nil
export function ApplicationPagesF95489180a414baf817fF844de4b5768(props: any) {
  return (
    <SecondaryPage85be3607354943aaB96aD77baa28d19b parentTag="ApplicationPagesF95489180a414baf817fF844de4b5768" header={<SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="Header205b1a64218141aaA51eFef1ec9410c8" title="Join Us" />} {...props} />
  );
}

// id: "5b694d15-0996-47fb-950d-cda25c1b86e8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "cf408b53-7369-42b2-911e-6f48e17451fb"
export function Header5b694d15099647fb950dCda25c1b86e8(props: any) {
  return (
    <MembersAreaHeader8d6594ab181a4e14B53cB6befe24c535 parentTag="Header5b694d15099647fb950dCda25c1b86e8" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "47177e59-04a9-40ab-a20d-67f04b7e0b8c"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "aba12e11-2e99-40ef-9c19-93f4f7233f56"
export function Copyright47177e5904a940abA20d67f04b7e0b8c(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright className="Copyright47177e5904a940abA20d67f04b7e0b8c" parentTag={buildClassName("Copyright47177e5904a940abA20d67f04b7e0b8c", parentTag)} {...props} />
  );
}

// id: "b8de342d-95a4-46b4-b10e-aaf7432fb400"
// title: ""
// type: :reference
// key: "header"
// parent_id: "31e6d138-756b-43cb-b94a-f56d174387d0"
export function HeaderB8de342d95a446b4B10eAaf7432fb400(props: any) {
  return (
    <SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="HeaderB8de342d95a446b4B10eAaf7432fb400" title="About Us" {...props} />
  );
}

// id: "9c13015c-dc8d-4007-b354-c6358d2af5a1"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "20044cc4-71c7-4410-9056-e2d0d4421d55"
export const NavMenuSlug9c13015cDc8d4007B354C6358d2af5a1 = "marketing-primary-nav";

// id: "cc9ab065-ed79-46bf-9f46-f071e0ee5535"
// title: ""
// type: :gf_preset
// key: "label"
// parent_id: "949542aa-2cc8-4cfb-ae97-70641f10a637"
export function LabelCc9ab065Ed7946bf9f46F071e0ee5535(props: any) {
  const Image = AppStore.presets["Image"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Image className="LabelCc9ab065Ed7946bf9f46F071e0ee5535" parentTag={buildClassName("LabelCc9ab065Ed7946bf9f46F071e0ee5535", parentTag)} contentSlug="header-logo" {...props} />
  );
}

// id: "5c016b6a-c220-4365-bf34-33aef4d89ac7"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "c7cb2096-b384-4045-9309-da741c70e1ab"
export const ContentSlug5c016b6aC2204365Bf3433aef4d89ac7 = "home-page-quote";

// id: "2335f0ef-2071-4563-90e3-d6f08012f16c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "26151921-58e9-460a-9674-cce7e2b3299f"
export const NavMenuSlug2335f0ef2071456390e3D6f08012f16c = "members-primary-nav";

// id: "cd030d72-bcdb-4739-93cf-523fdc711047"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesCd030d72Bcdb473993cf523fdc711047(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesCd030d72Bcdb473993cf523fdc711047 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader8d6594ab181a4e14B53cB6befe24c535 parentTag="HeaderB8e05f0f45af48a28aac2bf48b88da34" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation className="SecondaryNavCeeb00119c1d466f89daF6f941146bcc" parentTag={buildClassName("SecondaryNavCeeb00119c1d466f89daF6f941146bcc", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="Footer3af318d78f3d4c23BdedC1c8f2e2a665" {...props} />}
    </div>
  );
}

// id: "b9768ed0-a60b-467f-96fd-fad263a63d42"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42 hero`}>
      <h1 className="title">
        {props["title"] || TitleB904d44640394cd4B41b7be20fed9b17}
      </h1>
    </div>
  );
}

// id: "b8e05f0f-45af-48a2-8aac-2bf48b88da34"
// title: ""
// type: :reference
// key: "header"
// parent_id: "cd030d72-bcdb-4739-93cf-523fdc711047"
export function HeaderB8e05f0f45af48a28aac2bf48b88da34(props: any) {
  return (
    <MembersAreaHeader8d6594ab181a4e14B53cB6befe24c535 parentTag="HeaderB8e05f0f45af48a28aac2bf48b88da34" {...props} />
  );
}

// id: "ddeb2786-55c4-4e0e-81a7-ee4e45856eec"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "9174296c-d308-45c4-be3d-ef3bffb26e4e"
export function LinkedLogoDdeb278655c44e0e81a7Ee4e45856eec(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link className="LinkedLogoDdeb278655c44e0e81a7Ee4e45856eec" parentTag={buildClassName("LinkedLogoDdeb278655c44e0e81a7Ee4e45856eec", parentTag)} label={<DefaultLogo5124ace5621e4b53B391D6c5a1807f14 parentTag="Label8a892fb640bd4e57A6ab7040a53e5b3d" alt="logo" />} {...props} />
  );
}

// id: "318712f5-5fe9-4a33-b32b-77681093bf75"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "3977ece9-7988-4a97-8c89-10af77797711"
export function HeaderTop318712f55fe94a33B32b77681093bf75(props: any) {
  return (
    <DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a parentTag="HeaderTop318712f55fe94a33B32b77681093bf75" {...props} />
  );
}

// id: "c7cb2096-b384-4045-9309-da741c70e1ab"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "1919f1cc-04a8-49d4-a64d-52056612c12c"
export function QuoteC7cb2096B38440459309Da741c70e1ab(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote className="QuoteC7cb2096B38440459309Da741c70e1ab" parentTag={buildClassName("QuoteC7cb2096B38440459309Da741c70e1ab", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "f9ae490e-78df-4506-9d19-ee0d3dd95115"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "da21147c-d28b-4623-b4cb-9eb333a09dfa"
export const ContentSlugF9ae490e78df45069d19Ee0d3dd95115 = "footer-logo";

// id: "14f5d644-de8b-41de-a73a-3e2e389665eb"
// title: ""
// type: :text
// key: "title"
// parent_id: "ca64e924-0eb6-431c-92ea-2d3457ed7a7f"
export const Title14f5d644De8b41deA73a3e2e389665eb = "Secondary Page";

// id: "90ce60c0-bdad-4aaf-b1a6-923a63568690"
// title: ""
// type: :html
// key: "hero-wrapper"
// parent_id: "badcdfaa-842f-46ad-92d1-becc628605f1"
export function HeroWrapper90ce60c0Bdad4aafB1a6923a63568690(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HeroWrapper90ce60c0Bdad4aafB1a6923a63568690`}>
      {props["hero"] || <Hero className="Hero6ed8e8f0De0646248262A7cafe610577" parentTag={buildClassName("Hero6ed8e8f0De0646248262A7cafe610577", parentTag)} postSlug="home-hero" {...props} />}
    </div>
  );
}

// id: "cf408b53-7369-42b2-911e-6f48e17451fb"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultCf408b53736942b2911e6f48e17451fb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultCf408b53736942b2911e6f48e17451fb page`}>
      {props["header"] || <MembersAreaHeader8d6594ab181a4e14B53cB6befe24c535 parentTag="Header5b694d15099647fb950dCda25c1b86e8" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="Footer58d38ef9C841494b9a305e1dc92f929c" {...props} />}
    </div>
  );
}

// id: "31089ac3-4d3d-4104-919e-d0863d274c31"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header31089ac34d3d4104919eD0863d274c31(props: any) {
  return (
    <MembersAreaHeader8d6594ab181a4e14B53cB6befe24c535 parentTag="Header31089ac34d3d4104919eD0863d274c31" {...props} />
  );
}

// id: "b1618c08-1c99-4cbe-8a81-fff9f7ba1aaf"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "1919f1cc-04a8-49d4-a64d-52056612c12c"
export function WelcomeBackB1618c081c994cbe8a81Fff9f7ba1aaf(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp className="WelcomeBackB1618c081c994cbe8a81Fff9f7ba1aaf" parentTag={buildClassName("WelcomeBackB1618c081c994cbe8a81Fff9f7ba1aaf", parentTag)} {...props} />
  );
}

// id: "205b1a64-2181-41aa-a51e-fef1ec9410c8"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f9548918-0a41-4baf-817f-f844de4b5768"
export function Header205b1a64218141aaA51eFef1ec9410c8(props: any) {
  return (
    <SecondaryMarketingHeader3977ece979884a978c8910af77797711 parentTag="Header205b1a64218141aaA51eFef1ec9410c8" title="Join Us" {...props} />
  );
}

// id: "8f0c4f14-3df1-4efb-820b-569541c3198a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b76a3e13-4b3a-4766-8025-76ef2fba66d6"
export function Footer8f0c4f143df14efb820b569541c3198a(props: any) {
  return (
    <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="Footer8f0c4f143df14efb820b569541c3198a" {...props} />
  );
}

// id: "041be374-c701-4d4a-84df-3d179a45590f"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "5124ace5-621e-4b53-b391-d6c5a1807f14"
export const ImageUrl041be374C7014d4a84df3d179a45590f = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "220340be-30d2-40dc-8dd6-00fc979c482a"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "badcdfaa-842f-46ad-92d1-becc628605f1"
export const BackgroundImage220340be30d240dc8dd600fc979c482a = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "4438d619-7885-4b19-b91b-1fc6d90e13ed"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "624d74d0-1b44-47b5-93ba-1c394b5d5e5a"
export const NavMenuSlug4438d61978854b19B91b1fc6d90e13ed = "footer-nav";

// id: "c88fdd4b-4d61-4041-ab68-f821d9d3a09e"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "9cbfd509-004f-4a2b-b7ff-7d8e99f0554b"
export const PostSlugC88fdd4b4d614041Ab68F821d9d3a09e = "about-us-cards";

// id: "1919f1cc-04a8-49d4-a64d-52056612c12c"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage1919f1cc04a849d4A64d52056612c12c(props: any) {
  const Header = AppStore.presets["Header"];
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const MarketingCards = AppStore.presets["MarketingCards"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage1919f1cc04a849d4A64d52056612c12c gf-page-layout`}>
      {props["header-wrapper"] || <HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1 />}
      {props["welcome-back"] || <WelcomeBackPopUp className="WelcomeBackB1618c081c994cbe8a81Fff9f7ba1aaf" parentTag={buildClassName("WelcomeBackB1618c081c994cbe8a81Fff9f7ba1aaf", parentTag)} {...props} />}
      {props["main"] || <Main848b09bcF75a4e389181E4ac247ad201 />}
      {props["quote"] || <PageQuote className="QuoteC7cb2096B38440459309Da741c70e1ab" parentTag={buildClassName("QuoteC7cb2096B38440459309Da741c70e1ab", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="Footer7f5c1e5cFc2a4ec3A6ac8be9977cc301" {...props} />}
    </div>
  );
}

// id: "7f5c1e5c-fc2a-4ec3-a6ac-8be9977cc301"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "1919f1cc-04a8-49d4-a64d-52056612c12c"
export function Footer7f5c1e5cFc2a4ec3A6ac8be9977cc301(props: any) {
  return (
    <DefaultFooterAba12e112e9940ef9c1993f4f7233f56 parentTag="Footer7f5c1e5cFc2a4ec3A6ac8be9977cc301" {...props} />
  );
}

// id: "407ae049-6654-4d25-b2fe-ae41e7db6e9b"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "60cfe84d-c803-4879-a4b3-e823ff2fefef"
export function Button407ae04966544d25B2feAe41e7db6e9b(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button className="Button407ae04966544d25B2feAe41e7db6e9b" parentTag={buildClassName("Button407ae04966544d25B2feAe41e7db6e9b", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "da21147c-d28b-4623-b4cb-9eb333a09dfa"
// title: ""
// type: :gf_preset
// key: "logo"
// parent_id: "aba12e11-2e99-40ef-9c19-93f4f7233f56"
export function LogoDa21147cD28b4623B4cb9eb333a09dfa(props: any) {
  const Image = AppStore.presets["Image"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Image className="LogoDa21147cD28b4623B4cb9eb333a09dfa" parentTag={buildClassName("LogoDa21147cD28b4623B4cb9eb333a09dfa", parentTag)} contentSlug="footer-logo" {...props} />
  );
}

// id: "b68e6adf-f2a6-4136-823f-975389e6c71a"
// title: ""
// type: :html
// key: "main"
// parent_id: "31e6d138-756b-43cb-b94a-f56d174387d0"
export function MainB68e6adfF2a64136823f975389e6c71a(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} MainB68e6adfF2a64136823f975389e6c71a`}>
      <div className="cards-wrapper">
        {props["cards"] || <MarketingCards className="Cards9cbfd509004f4a2bB7ff7d8e99f0554b" parentTag={buildClassName("Cards9cbfd509004f4a2bB7ff7d8e99f0554b", parentTag)} postSlug="about-us-cards" {...props} />}
      </div>
    </main>
  );
}

// id: "995d86c9-ea6c-40ed-9b69-27e101ffc072"
// title: ""
// type: :text
// key: "href"
// parent_id: "407ae049-6654-4d25-b2fe-ae41e7db6e9b"
export const Href995d86c9Ea6c40ed9b6927e101ffc072 = "/join";

// id: "2f90f627-1913-4dfb-bb5e-ae907a1db2bb"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "5b694d15-0996-47fb-950d-cda25c1b86e8"
export const NavMenuSlug2f90f62719134dfbBb5eAe907a1db2bb = "members-primary-nav";

// id: "c57045b1-7251-427e-aa98-21089566a04d"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "b98a3e4d-5258-4f4c-884b-307b7f4c5c62"
export const ButtonClassC57045b17251427eAa9821089566a04d = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "9a8ec972-0def-48ef-bcaa-f817c24dc6cb"
// title: ""
// type: :text
// key: "title"
// parent_id: "b8de342d-95a4-46b4-b10e-aaf7432fb400"
export const Title9a8ec9720def48efBcaaF817c24dc6cb = "About Us";

// id: "d2f5d2c9-0b1a-4dec-bdb4-1af711de8ad4"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "b98a3e4d-5258-4f4c-884b-307b7f4c5c62"
export const PostSlugD2f5d2c90b1a4decBdb41af711de8ad4 = "home-page-cards";

const Components = {
  FooterD89c178b10f8461b813aAd4878071bf0,
  Title834b0d466a9a4d8bAb75A3db89204e19,
  SecondaryMarketingHeader3977ece979884a978c8910af77797711,
  SecondaryPage85be3607354943aaB96aD77baa28d19b,
  HomePageHero60cfe84dC8034879A4b3E823ff2fefef,
  TitleB904d44640394cd4B41b7be20fed9b17,
  Title3775cfa929ea40f8A61077da0a33d6d1,
  HeaderTop4ce0202f71c9404cBeb9D46fe30ad868,
  TitleA0c5f32360db4714A32357689b12d404,
  ContentSlug8c185371F5e04bcdBa340f36fc317e5f,
  DefaultHeaderTop1aa3b511D6bb49e1Be5bE653345da47a,
  Label3c4c61adF0374dff9971Ba28f42e3862,
  ShortHeader9174296cD30845c4Be3dEf3bffb26e4e,
  Footer58d38ef9C841494b9a305e1dc92f929c,
  BackgroundImage9bad529a27f9459cB4de8ee7ed972e51,
  Navigation2615192158e9460a9674Cce7e2b3299f,
  HeaderWrapperBadcdfaa842f46ad92d1Becc628605f1,
  NavMenuSlug8f63071a30f749d5852f1fb0c296bde2,
  DefaultFooterAba12e112e9940ef9c1993f4f7233f56,
  DefaultLogo5124ace5621e4b53B391D6c5a1807f14,
  TitleAf17238e1f9149bf931c153d55fb999d,
  PageQuote071cea74F9e847949f6b951fbe480b76,
  Main848b09bcF75a4e389181E4ac247ad201,
  HeaderCa64e9240eb6431c92ea2d3457ed7a7f,
  Header12f87131201c45799113808092593756,
  HeaderTop97367487Bcc7434991e254eff4b74849,
  CardsB98a3e4d52584f4c884b307b7f4c5c62,
  Title987fb541135543b2808f5476b11a03a7,
  NavMenuSlugD92826d18d2f47f492acE633e1ef2a03,
  Hero6ed8e8f0De0646248262A7cafe610577,
  DefaultHeader20044cc471c744109056E2d0d4421d55,
  JoinUsPageF4ea8e04Cfb94a07887aE32368621940,
  Links624d74d01b4447b593ba1c394b5d5e5a,
  FooterBf48e9ce86144cc09d526a50c37f3e26,
  HeaderD664f739Aba84000Bf53Feb092f3906a,
  Class86f5e3feBc4543e2A77005abc1fc97da,
  Title9b89776137e94a87B76f503ae60ca1d6,
  MembersAreaHeader8d6594ab181a4e14B53cB6befe24c535,
  Href41caf18b482e4c6e902f3af664af9fa3,
  HeroD5b7f61a92ed48eb92aa3b8e117f581c,
  LinkedLogo949542aa2cc84cfbAe9770641f10a637,
  Alt744b21a99cb94939805309ad517658ad,
  Class5f6e15bb8e7745a494036cdf4408fef6,
  Label27b3a30d95f14c8f9d2f1cda17d1b6a9,
  Label8a892fb640bd4e57A6ab7040a53e5b3d,
  PostSlug7d79d5a9F061415eB044B1c2bb437d01,
  AboutUsPage31e6d138756b43cbB94aF56d174387d0,
  FooterB8ac37ab095c4f7aA9ec5a9035ed674c,
  Footer3af318d78f3d4c23BdedC1c8f2e2a665,
  SecondaryNavCeeb00119c1d466f89daF6f941146bcc,
  NavigationB3387485Aba1472d976d39ca49982d63,
  Groupflow38cc4b57776144098ba6Ca89e0f87d5a,
  Cards9cbfd509004f4a2bB7ff7d8e99f0554b,
  UrlA6427e3c26b742699ac4C073cea30485,
  Title6d55e11b802e49eb917b73560a09ac15,
  PublicEventsPageB76a3e134b3a4766802576ef2fba66d6,
  ApplicationPagesF95489180a414baf817fF844de4b5768,
  Header5b694d15099647fb950dCda25c1b86e8,
  Copyright47177e5904a940abA20d67f04b7e0b8c,
  HeaderB8de342d95a446b4B10eAaf7432fb400,
  NavMenuSlug9c13015cDc8d4007B354C6358d2af5a1,
  LabelCc9ab065Ed7946bf9f46F071e0ee5535,
  ContentSlug5c016b6aC2204365Bf3433aef4d89ac7,
  NavMenuSlug2335f0ef2071456390e3D6f08012f16c,
  MemberPagesCd030d72Bcdb473993cf523fdc711047,
  SecondaryMarketingHeroB9768ed0A60b467f96fdFad263a63d42,
  HeaderB8e05f0f45af48a28aac2bf48b88da34,
  LinkedLogoDdeb278655c44e0e81a7Ee4e45856eec,
  HeaderTop318712f55fe94a33B32b77681093bf75,
  QuoteC7cb2096B38440459309Da741c70e1ab,
  ContentSlugF9ae490e78df45069d19Ee0d3dd95115,
  Title14f5d644De8b41deA73a3e2e389665eb,
  HeroWrapper90ce60c0Bdad4aafB1a6923a63568690,
  MembersAreaDefaultCf408b53736942b2911e6f48e17451fb,
  Header31089ac34d3d4104919eD0863d274c31,
  WelcomeBackB1618c081c994cbe8a81Fff9f7ba1aaf,
  Header205b1a64218141aaA51eFef1ec9410c8,
  Footer8f0c4f143df14efb820b569541c3198a,
  ImageUrl041be374C7014d4a84df3d179a45590f,
  BackgroundImage220340be30d240dc8dd600fc979c482a,
  NavMenuSlug4438d61978854b19B91b1fc6d90e13ed,
  PostSlugC88fdd4b4d614041Ab68F821d9d3a09e,
  HomePage1919f1cc04a849d4A64d52056612c12c,
  Footer7f5c1e5cFc2a4ec3A6ac8be9977cc301,
  Button407ae04966544d25B2feAe41e7db6e9b,
  LogoDa21147cD28b4623B4cb9eb333a09dfa,
  MainB68e6adfF2a64136823f975389e6c71a,
  Href995d86c9Ea6c40ed9b6927e101ffc072,
  NavMenuSlug2f90f62719134dfbBb5eAe907a1db2bb,
  ButtonClassC57045b17251427eAa9821089566a04d,
  Title9a8ec9720def48efBcaaF817c24dc6cb,
  PostSlugD2f5d2c90b1a4decBdb41af711de8ad4
}

export default Components;
import { FileInput } from "components/form";
import { RefObject, useRef } from "react";
import HollowButton from "../ui/HollowButton";
import { readURL } from "lib/image";
import { postUpload } from "lib/gf-api";
import { useApiCallParams } from "lib/gf-app-context";
import { UploadParams, UploadType } from "types/upload";
import { SimpleImageUpload } from "types/photo";
interface Props {
  additionalAccepts?: string[];
  imageRef?: RefObject<HTMLImageElement | null>;
  imageUploaded?: (url: string) => void;
  imageUploaded2?: (image: SimpleImageUpload) => void;
  name?: string;
  uploadType?: UploadType;
}
export default function FileUpload(props: Props) {
  const {
    additionalAccepts,
    imageRef,
    name = "fileUpload"
  } = props;
  const apiCall = useApiCallParams();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  async function onFileSelected(file: File) {
    const imageEl = imageRef && imageRef.current;
    if (imageEl) {
      readURL(imageEl, file);
    }
    let params: UploadParams = {
      file
    };
    if (props.uploadType) {
      params = {
        ...params,
        uploadType: props.uploadType
      };
    }
    const result = await postUpload(apiCall, params);
    props.imageUploaded && props.imageUploaded(result.url);
    if (props.imageUploaded2) {
      const photo: SimpleImageUpload = {
        id: result.photoId,
        url: result.url
      };
      props.imageUploaded2(photo);
    }
  }
  return <div data-sentry-component="FileUpload" data-sentry-source-file="FileUpload.tsx">
      <FileInput onChange={onFileSelected} className="hidden" name={name} inputRef={fileInputRef} additionalAccepts={additionalAccepts} data-sentry-element="FileInput" data-sentry-source-file="FileUpload.tsx" />
      <HollowButton onClick={() => fileInputRef.current?.click()} data-sentry-element="HollowButton" data-sentry-source-file="FileUpload.tsx">
        Select file...
      </HollowButton>
    </div>;
}
import SimpleModal from "components/SimpleModal";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  src: string;
}
export default function ImageDetailModal(props: Props) {
  const {
    isOpen,
    onClose,
    src
  } = props;
  const bgSrc = "https://mp1md-pub.s3.amazonaws.com/groupflow/images/checkerboard.png";
  return <SimpleModal isOpen={isOpen} onClose={onClose} data-sentry-element="SimpleModal" data-sentry-component="ImageDetailModal" data-sentry-source-file="ImageDetailModal.tsx">
      <div className="relative w-fit m-4">
        <div style={{
        backgroundImage: `url(${bgSrc})`,
        backgroundSize: "10px"
      }} className="absolute inset-0 background-repeat opacity-20" />
        <img src={src} alt="image detail" className="relative" />
      </div>
    </SimpleModal>;
}
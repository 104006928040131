import ImageDetailModal from "components/image/ImageDetailModal";
import { RefObject, useState } from "react";
interface Props {
  id?: string;
  imageRef?: RefObject<HTMLImageElement | null>;
  src?: string | null | undefined;
}
export default function ImageFieldPreview(props: Props) {
  const {
    id,
    imageRef,
    src
  } = props;
  if (!src) return null;
  const [isOpen, setIsOpen] = useState(false);
  const bgSrc = "https://mp1md-pub.s3.amazonaws.com/groupflow/images/checkerboard.png";
  return <div data-sentry-component="ImageFieldPreview" data-sentry-source-file="ImageFieldPreview.tsx">
      <button onClick={() => setIsOpen(true)} className="relative" type="button">
        <div style={{
        backgroundImage: `url(${bgSrc})`,
        backgroundSize: "10px"
      }} className="absolute inset-0 background-repeat opacity-10" />
        <img src={src} className="relative h-12" ref={imageRef} id={id} />
      </button>
      <ImageDetailModal isOpen={isOpen} onClose={() => setIsOpen(false)} src={src} data-sentry-element="ImageDetailModal" data-sentry-source-file="ImageFieldPreview.tsx" />
    </div>;
}
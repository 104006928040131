import EditableSection from "components/contentEditing/EditableSection";
import { usePosts } from "hooks/content";
import { observer } from "mobx-react-lite";
import { SimplifiedContent } from "types/content";
interface Props {
  className?: string;
  contentSlug: string;
}
function Image(props: Props) {
  const {
    className,
    contentSlug
  } = props;
  const {
    getPostListBySlug: getPost
  } = usePosts();
  const contentItem = contentSlug ? getPost(contentSlug) : undefined;
  const src = contentItem?.photo?.url || "https://mp1md-pub.s3.amazonaws.com/images/generic-image.svg";
  return <EditableSection showEditButton={false} editById={contentItem ? {
    id: contentItem?.id,
    baseType: "photo",
    webComponentName: "Image"
  } : undefined} editBySlug={contentSlug && !contentItem ? {
    slug: contentSlug,
    baseType: "photo",
    plurality: "item",
    webComponentName: "Image"
  } : undefined} className="image-content" data-sentry-element="EditableSection" data-sentry-component="Image" data-sentry-source-file="Image.tsx">
      <img src={src} className={className} />
    </EditableSection>;
}
export default observer(Image);
export const IMAGE_ATTRIBUTES = [{
  key: "contentSlug",
  type: "string"
}];
export const IMAGE_CONTENT_SCHEMA: SimplifiedContent = {
  isParentEditable: true,
  title: "Image",
  type: "image"
};
/*!
 * ContentSection (Post) plugin for WebComponents
 */

import clsx from "clsx";
import MarkdownContent from "components/MarkdownContent";
import EditableSection from "components/contentEditing/EditableSection";
import { usePosts } from "hooks/content";
import { observer } from "mobx-react-lite";
import { SimplifiedContent } from "types/content";
interface Props {
  className?: string;
  contentSlug?: string;
}
const ContentSnippet = (props: Props) => {
  const {
    className,
    contentSlug
  } = props;
  const {
    getPostListBySlug: getPost
  } = usePosts();
  const post = contentSlug ? getPost(contentSlug) : undefined;
  function renderContent() {
    if (!post?.content) return <div>Edit this text</div>;
    if (post.contentType === "text/html") {
      return <div className="inner" dangerouslySetInnerHTML={{
        __html: post.content
      }} />;
    } else if (post.contentType === "text/markdown") {
      return <MarkdownContent content={post.content} />;
    }
  }
  return <EditableSection className={clsx("ContentSnippet EditableSection", className)} editById={post ? {
    id: post?.id,
    baseType: "text",
    webComponentName: "ContentSnippet"
  } : undefined} editBySlug={contentSlug && !post ? {
    slug: contentSlug,
    baseType: "text",
    subType: "post",
    plurality: "item",
    webComponentName: "ContentSnippet"
  } : undefined} data-sentry-element="EditableSection" data-sentry-component="ContentSnippet" data-sentry-source-file="ContentSnippet.tsx">
      {renderContent()}
    </EditableSection>;
};
export default observer(ContentSnippet);
export const CONTENT_SNIPPET_ATTRIBUTES = [{
  key: "className",
  type: "string"
}, {
  key: "contentSlug",
  type: "string"
}];
export const CONTENT_SNIPPET_CONTENT_SCHEMA: SimplifiedContent = {
  isParentEditable: true,
  type: "text",
  fieldSize: "large",
  title: "Body"
};
import clsx from "clsx";
import React, { SyntheticEvent } from "react";
import { DashStyle } from "types/content";
interface Props {
  onClick?: () => void;
  dashStyle?: DashStyle;
}
export default function SelectionArea(props: Props) {
  const {
    dashStyle
  } = props;
  function svgRef(el: SVGElement | null) {
    if (el) {
      el.onmouseover = function () {
        const rectEl = el.querySelector("rect");
        rectEl?.classList.add("rect-hover");
      };
      el.onmouseout = function () {
        const rectEl = el.querySelector("rect");
        rectEl?.classList.remove("rect-hover");
      };
    }
  }
  const dashClass = dashStyle === "thick" ? "thicker" : "";
  return <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" preserveAspectRatio="none" className={clsx("selection-area base", dashClass)} ref={svgRef} style={{
      zIndex: 10
    }} data-sentry-element="svg" data-sentry-source-file="SelectionArea.tsx">
        <rect width="40" height="40" data-sentry-element="rect" data-sentry-source-file="SelectionArea.tsx" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" preserveAspectRatio="none" className={clsx("selection-area dashes", dashClass)} ref={svgRef} style={{
      zIndex: 10
    }} data-sentry-element="svg" data-sentry-source-file="SelectionArea.tsx">
        <rect width="40" height="40" data-sentry-element="rect" data-sentry-source-file="SelectionArea.tsx" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" preserveAspectRatio="none" className={clsx("selection-area top cursor-pointer", dashClass)} ref={svgRef} onClick={(e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      props.onClick && props.onClick();
    }} style={{
      zIndex: 10
    }} data-sentry-element="svg" data-sentry-source-file="SelectionArea.tsx">
        <rect width="40" height="40" data-sentry-element="rect" data-sentry-source-file="SelectionArea.tsx" />
      </svg>
    </>;
}
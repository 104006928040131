// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import clsx from "clsx";
import Link from "next/link";
import React from "react";
import AdminActionButton from "components/admin/ui/ActionButton";
import { ThemeArea } from "types/theme";
import Spinner from "components/icon/Spinner";
interface Props {
  bgColorClass?: string;
  children?: React.ReactNode;
  className?: string;
  colorClass?: string;
  disabled?: boolean;
  download?: string;
  href?: string;
  id?: string;
  inputRef?: React.RefCallback<HTMLButtonElement>;
  leftIcon?: React.ReactNode;
  loading?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  paddingClass?: string;
  rel?: string;
  savingLabel?: string;
  target?: string;
  themeArea?: ThemeArea;
  type?: "button" | "submit";
  withGroupFlow?: boolean;
}
const noClick = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
};
const ActionButton = function (props: Props) {
  const {
    href,
    id,
    themeArea,
    loading,
    children,
    leftIcon,
    savingLabel
  } = props;
  let {
    disabled
  } = props;
  const type = props.type || "button";
  const onClick = loading ? noClick : props.onClick;
  let colorClass = props.colorClass || "text-[var(--group-text-over-accent)]";
  let label;
  if (loading) {
    disabled = true;
    label = savingLabel || "Loading…";
  } else {
    label = children;
  }
  let bgColorFilter = "";
  let bgColorHover = "";
  let cursorClass = "";
  if (disabled) {
    bgColorFilter = "saturate-50";
    cursorClass = "cursor-not-allowed";
  } else {
    bgColorHover = "hover:bg-[var(--group-accent-lighter)]";
  }
  let bgColorClass = props.bgColorClass;
  bgColorClass = bgColorClass || (themeArea === "admin" ? undefined : "bg-[var(--group-accent)]");
  const cornerRadius = "rounded-[var(--group-button-radius)]";
  const className = clsx("SubmitButton Button inline-flex flex-row gap-2 items-center justify-center transition", "min-w-32", bgColorClass, bgColorFilter, bgColorHover, colorClass, "font-semibold", props.paddingClass || "py-2.5 px-4.5", cornerRadius, "shadow", props.className, cursorClass);
  function onLinkClick(ev: any) {
    if (disabled) {
      ev.preventDefault();
      ev.stopPropagation();
    }
  }
  let icon = null;
  if (loading) {
    icon = <Spinner className="h-5 w-5 mr-2" />;
  } else if (props.withGroupFlow) {
    icon = <img src="https://mp1md-pub.s3.amazonaws.com/groupflow/images/icon-white.svg" alt="GroupFlow" className="w-6 h-6" />;
  }
  const supplementedChildren = <>
      {icon}
      {label}
    </>;
  if (themeArea === "admin") {
    return <AdminActionButton bgColorClass={props.bgColorClass} disabled={props.disabled} leftIcon={leftIcon} loading={props.loading} onClick={props.onClick} type={props.type} withGroupFlow={props.withGroupFlow}>
        {props.children}
      </AdminActionButton>;
  }
  if (href) {
    return <Link download={props.download} href={href} id={id} className={className} onClick={onLinkClick} target={props.target} rel={props.rel}>
        {supplementedChildren}
      </Link>;
  }
  return <button type={type} id={id} className={className} onClick={onClick} disabled={disabled} ref={props.inputRef}>
      {supplementedChildren}
    </button>;
};
export default ActionButton;
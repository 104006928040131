import { MenuItem } from "@headlessui/react";
import Link from "next/link";
interface LinkMenuItemProps {
  href: string;
  icon?: React.ReactNode;
  label: string | React.ReactNode;
  menuIsInline?: boolean;
}
export default function LinkMenuItem(props: LinkMenuItemProps) {
  const {
    href,
    icon,
    menuIsInline,
    label
  } = props;
  if (menuIsInline) {
    return <div className="block py-2 data-[focus]:bg-[var(--group-accent-ultralight)]">
        <Link href={href} className="flex justify-start gap-x-2">
          <span className="mt-[0.15rem]">{icon}</span>
          {label}
        </Link>
      </div>;
  }
  return <MenuItem {...{
    className: "block py-2 px-6 data-[focus]:bg-[var(--group-accent-ultralight)]"
  }} data-sentry-element="MenuItem" data-sentry-component="LinkMenuItem" data-sentry-source-file="LinkMenuItem.tsx">
      <Link href={href} className="flex justify-start gap-x-2" data-sentry-element="Link" data-sentry-source-file="LinkMenuItem.tsx">
        <span className="mt-[0.15rem]">{icon}</span>
        {label}
      </Link>
    </MenuItem>;
}
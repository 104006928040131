import { ActionButton, ErrorMessage, RowLabel, TextInput } from "components/form";
import { UseFormReturn } from "react-hook-form";
import { EmailSetForm } from "types/account";
import { MutationInfo } from "types/ash";
import useAutoFocus from "hooks/useAutoFocus";
import ModalHeading from "components/admin/ui/modal/ModalHeading";
import AshMutationErrors from "components/alerts/AshMutationErrors";
interface Props {
  form: UseFormReturn<EmailSetForm>;
  originalEmail: string | null | undefined;
  mutationState: MutationInfo<any>;
}
export default function PromptEmailAddress(props: Props) {
  const {
    form,
    mutationState,
    originalEmail
  } = props;
  const {
    formState,
    register
  } = form;
  const {
    errors
  } = formState;
  const focusCloseButton = useAutoFocus();
  return <>
      <div>
        <ModalHeading data-sentry-element="ModalHeading" data-sentry-source-file="PromptEmailAddress.tsx">Set your email address</ModalHeading>
        {!originalEmail && <p>Please set your email address to secure your account.</p>}
      </div>
      {!originalEmail && <div>
          <RowLabel htmlFor="field-email">Email address</RowLabel>
          <TextInput type="email" id="field-email" register={register("email", {
        required: true,
        pattern: /^\S+@\S+\.\w+$/
      })} style={{
        display: "inline-block"
      }} placeholder="you@example.com" />
          <ErrorMessage errors={errors} name="email" messages={{
        pattern: "This does not appear to be a valid email address"
      }} />
        </div>}

      <AshMutationErrors mutationInfo={mutationState} data-sentry-element="AshMutationErrors" data-sentry-source-file="PromptEmailAddress.tsx" />

      {!originalEmail && <div className="flex flex-col items-center">
          <ActionButton type="submit" loading={mutationState.loading}>
            Send confirmation email
          </ActionButton>
        </div>}

      {originalEmail && <div className="flex flex-col items-center">
          <ActionButton type="submit" inputRef={focusCloseButton}>
            Close
          </ActionButton>
        </div>}
    </>;
}
/*!
 * v2 MarketingCardsList
 *
 * This version removes support for @craftjs, and adds support for WebComponents.
 */

import EditableSection from "components/contentEditing/EditableSection";
import MarkdownContent from "components/MarkdownContent";
import { usePosts } from "hooks/content";
import { observer } from "mobx-react-lite";
import { ContentItem } from "types/content";
import MarketingCard from "./MarketingCard";
import { buildClassName } from "lib/string";
interface Props {
  buttonClass?: string;
  className?: string;
  parentTag?: string;
  postsItem?: ContentItem;
  postSlug?: string;
  titleColor?: string;
  imageAs?: "background" | "image";
}
const MarketingCards = ({
  buttonClass,
  className,
  imageAs,
  parentTag,
  postsItem,
  postSlug
}: Props) => {
  const {
    getPostListBySlug: getPost
  } = usePosts();
  if (!postsItem && postSlug) {
    postsItem = getPost(postSlug);
  }
  return <EditableSection className={buildClassName(parentTag, "MarketingCards", className)} editById={postsItem ? {
    id: postsItem?.id,
    baseType: "text"
  } : undefined} editBySlug={postSlug && !postsItem ? {
    slug: postSlug,
    baseType: "text",
    subType: "post",
    plurality: "list"
  } : undefined} data-sentry-element="EditableSection" data-sentry-component="MarketingCards" data-sentry-source-file="MarketingCards.tsx">
      {postsItem?.children?.map((post, index) => <MarketingCard className={post.slug ? `slug-${post.slug}` : undefined} key={post.id} index={index} buttonClass={buttonClass} imageUri={post.children?.find(p => p.baseType === "photo")?.photo?.url || ""} heading={post.title} buttonLabel={post.children?.find(c => c.baseType === "nav")?.label} buttonUrl={post.children?.find(c => c.baseType === "nav")?.uri || ""} order={index % 2 === 1 ? "flex-row-reverse" : "flex-row"} imageAs={imageAs}>
          <MarkdownContent content={post.content} />
        </MarketingCard>)}
    </EditableSection>;
};
export default observer(MarketingCards);
export const MARKETING_CARDS_ATTRIBUTES = [{
  key: "postSlug",
  type: "string"
}];
import { useState } from "react";
import AboutGroupFlowModal from "./AboutGroupFlowModal";
interface FormTitleProps {
  children: React.ReactNode;
  withGroupFlow?: boolean;
}
export default function FormTitle(props: FormTitleProps) {
  const {
    children,
    withGroupFlow
  } = props;
  const [aboutIsOpen, setAboutIsOpen] = useState(false);
  return <h2 className="flex justify-center items-center gap-x-4 text-center font-bold text-2xl text-secondary-950" data-sentry-component="FormTitle" data-sentry-source-file="FormTitle.tsx">
      {withGroupFlow && <button title="This website is powered by GroupFlow" onClick={() => setAboutIsOpen(true)} className="group w-8 h-8 min-w-8">
          <img src="https://mp1md-pub.s3.amazonaws.com/groupflow/images/icon-white-stroke.svg" alt="GroupFlow" className="opacity-50 group-hover:opacity-100" />
        </button>}
      {children}
      <AboutGroupFlowModal isOpen={aboutIsOpen} onClose={() => setAboutIsOpen(false)} data-sentry-element="AboutGroupFlowModal" data-sentry-source-file="FormTitle.tsx" />
    </h2>;
}
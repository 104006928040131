import clsx from "clsx";
import FileUpload from "components/admin/form/FileUpload";
import ImageFieldPreview from "components/admin/form/ImageFieldPreview";
import ModalHeading from "components/admin/ui/modal/ModalHeading";
import AshMutationResult from "components/alerts/AshMutationResult";
import { FormRow, SubmitButtonRow, TextArea, TextInput } from "components/form";
import SectionHeading from "components/form/SectionHeading";
import { WebComponentPresets } from "components/webComponent";
import { useApiHelper } from "lib/gf-api/api-react";
import { createContentItem2, getContentItem, updateContentItem2 } from "lib/gf-api/content-api";
import { useApiCallParams, useContentStore } from "lib/gf-app-context";
import Style from "lib/Style";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RequestError } from "types/api";
import { MutationInfo } from "types/ash";
import { ContentBaseType, ContentItemExtended, ContentItemParams, ContentPlurality, ContentSubType, ContentType, NavigationState, SimplifiedContent, SimplifiedContentItem } from "types/content";
import { SimpleImageUpload } from "types/photo";
interface Props {
  item: NavigationState;
  onCancel?: () => void;
}
interface Form {
  baseType?: ContentBaseType;
  children: ContentItemInput[];
  content?: string;
  imageUrl?: string;
  label?: string;
  photoId?: string;
  subType?: ContentSubType;
  uri?: string;
}
interface ContentItemInput {
  baseType: ContentBaseType;
  content: string;
  photoId?: string;
  label?: string;
  purpose: string | null;
  subType?: ContentSubType;
  uri?: string;
}
function WebComponentContentEdit(props: Props) {
  const form = useForm<Form>({
    defaultValues: {
      children: []
    }
  });
  const {
    handleSubmit,
    register,
    setValue,
    watch
  } = form;
  const {
    item
  } = props;
  const {
    webComponentName
  } = item;
  const apiParams = useApiCallParams();
  const [contentItem, setContentItem] = useState<ContentItemExtended | null>(null);
  const getCall = useApiHelper<ContentItemExtended>();
  const [mutationResult, setMutationResult] = useState<MutationInfo | undefined>(undefined);
  const contentStore = useContentStore();
  const preset = WebComponentPresets.find(p => p.name === webComponentName);
  const contentSchema = preset?.contentSchema;
  useEffect(() => {
    if (item.contentId && !contentItem) {
      loadContentItem(item.contentId);
    }
  }, [item.contentId]);

  // Populate form
  useEffect(() => {
    setForm();
  }, [WebComponentPresets]);
  async function onSubmit(data: Form) {
    if (!contentSchema) return;
    let baseType: ContentBaseType = "text";
    let contentType: ContentType | undefined = "text/markdown";
    let subType: ContentSubType | undefined = "post";
    let plurality: ContentPlurality = "list";
    let photoId: string | undefined;
    if (contentSchema.type === "image") {
      baseType = "photo";
      contentType = undefined;
      subType = undefined;
      plurality = "item";
      photoId = data.photoId;
    }
    let params: Partial<ContentItemParams> = {
      baseType,
      contentType,
      subType,
      plurality,
      children: data.children,
      photoId,
      slug: contentItem?.slug || props.item.initialParams?.slug,
      title: `${webComponentName} Content`
    };
    if (contentSchema.isParentEditable) {
      params = {
        ...params,
        content: data.content
      };
    }
    let result;
    if (contentItem) {
      params = {
        id: contentItem.id,
        ...params
      };
      result = await updateContentItem2(apiParams, params);
    } else {
      result = await createContentItem2(apiParams, params);
    }
    if (result.error) {
      const mutationResult: MutationInfo<any> = {
        data: null,
        loading: false,
        reset: () => setMutationResult(undefined),
        serverError: false,
        errors: result.requestErrors.map((e: RequestError) => ({
          message: e.title,
          code: e.code
        }))
      };
      setMutationResult(mutationResult);
    } else {
      const mutationResult: MutationInfo<any> = {
        data: result.data,
        loading: false,
        reset: () => setMutationResult(undefined),
        serverError: false,
        errors: []
      };
      setMutationResult(mutationResult);
      setContentItem(result.data);
      contentStore.reloadTextItems();
    }
  }
  async function loadContentItem(id: string) {
    const result = await getCall.wrapCall(call => getContentItem(call, id));
    if (!result.error) {
      const contentItem = result.data;
      setContentItem(contentItem);
      setForm(contentItem);
    }
  }
  function setForm(contentItem?: ContentItemExtended) {
    if (contentSchema?.isParentEditable) {
      setValue("baseType", "text");
      setValue("subType", "post");
      setValue("content", contentItem?.content || "");
    }
    contentSchema?.items?.forEach((item, index) => {
      setValue(`children.${index}.purpose`, item.purpose);
      const savedItem = contentItem?.children.find(c => c.purpose === item.purpose);
      if (item.type === "text") {
        setValue(`children.${index}.subType`, "post");
        setValue(`children.${index}.content`, savedItem?.content ?? "");
        setValue(`children.${index}.baseType`, "text");
      } else if (item.type === "button") {
        setValue(`children.${index}.baseType`, "text");
        setValue(`children.${index}.subType`, "button");
        setValue(`children.${index}.label`, savedItem?.label ?? "");
        setValue(`children.${index}.uri`, savedItem?.uri ?? "");
      } else if (item.type === "image") {
        setValue(`children.${index}.baseType`, "photo");
        if (savedItem?.photo) {
          setValue("imageUrl", savedItem.photo.url);
          setValue(`children.${index}.photoId`, savedItem.photo.id);
        }
      }
    });
  }
  if (!webComponentName) {
    return <div>No web component name found.</div>;
  }
  if (!preset) {
    return <div>Unknown web component preset: {props.item.webComponentName}</div>;
  }
  if (!contentSchema) {
    return <div>This preset does not support content editing.</div>;
  }
  function renderItemEditor(item: SimplifiedContent | SimplifiedContentItem, index?: number) {
    if (item.type === "text") {
      return <FormRow label={item.title}>
          <TextArea register={register(typeof index === "number" ? `children.${index}.content` : "content")} className={clsx(Style.textArea, item.fieldSize === "large" && "min-h-[20rem]")} />
        </FormRow>;
    } else if (item.type === "button") {
      return <div className={Style.formClass}>
          <SectionHeading>{item.title}</SectionHeading>

          <FormRow label="Label">
            <TextInput register={register(typeof index === "number" ? `children.${index}.label` : "label")} />
          </FormRow>

          <FormRow label="URI">
            <TextInput register={register(typeof index === "number" ? `children.${index}.uri` : "uri")} />
          </FormRow>
        </div>;
    } else if (item.type === "image") {
      return <div className={Style.formClass}>
          <SectionHeading>{item.title}</SectionHeading>
          <div className="flex flex-col gap-y-4 my-2">
            <ImageFieldPreview src={watch("imageUrl")} id="web-component-image-preview" />
            <FileUpload imageUploaded2={(image: SimpleImageUpload) => {
            setValue(typeof index === "number" ? `children.${index}.photoId` : "photoId", image.id);
            setValue("imageUrl", image.url);
          }} name="web-component-image-upload" uploadType="GENERIC_IMAGE" />
          </div>
        </div>;
    } else {
      return <>Unknown item type</>;
    }
  }
  return <form className={Style.formClass} onSubmit={handleSubmit(onSubmit)} data-sentry-component="WebComponentContentEdit" data-sentry-source-file="WebComponentContentEdit.tsx">
      <ModalHeading data-sentry-element="ModalHeading" data-sentry-source-file="WebComponentContentEdit.tsx">{webComponentName} Content</ModalHeading>
      {contentSchema.isParentEditable && renderItemEditor(contentSchema)}
      {contentSchema.items?.map((item, index) => <Fragment key={item.purpose}>{renderItemEditor(item, index)}</Fragment>)}
      <AshMutationResult mutationInfo={mutationResult} successMessage="Saved" data-sentry-element="AshMutationResult" data-sentry-source-file="WebComponentContentEdit.tsx" />
      <SubmitButtonRow themeArea="admin" cancel={{
      onClick: () => props.onCancel && props.onCancel()
    }} withGroupFlow data-sentry-element="SubmitButtonRow" data-sentry-source-file="WebComponentContentEdit.tsx" />
    </form>;
}
export default observer(WebComponentContentEdit);
import clsx from "clsx";
import useScriptableContent from "hooks/useScriptableContent";
import { marked } from "marked";
import { memo } from "react";
interface Props {
  content?: string;
  className?: string;
  onLoad?: (el: HTMLSpanElement) => void;
}
function MarkdownContent(props: Props) {
  const {
    content
  } = props;
  let {
    className
  } = props;
  className = clsx(className, "external-content");
  const {
    onExternalContentLoad
  } = useScriptableContent();
  function onLoad(el: HTMLSpanElement | null) {
    el && onExternalContentLoad(el);
  }
  function h(html: string) {
    return html.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;").replace(/&/g, "&amp;");
  }
  function decodeHtmlEncoded(string: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(string, "text/html");
    return doc.documentElement.textContent || "";
  }
  marked.use({
    extensions: [{
      name: "link",
      renderer(el: any) {
        // Decode HTML entities in el.href, and assign it to href
        const href = decodeHtmlEncoded(el.href);
        const text = decodeHtmlEncoded(el.text);
        return `<a href="${h(href)}" class="a">${h(text)}</a>`;
      }
    }]
  });
  return <span className={className} dangerouslySetInnerHTML={{
    __html: marked(content ?? "")
  }} ref={onLoad} data-sentry-component="MarkdownContent" data-sentry-source-file="MarkdownContent.tsx" />;
}
export default memo(MarkdownContent);
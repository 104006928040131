import clsx from "clsx";
import GroupFlowWideLogo from "components/icon/GroupFlowWideLogo";
import { useGroupConfig } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
interface Props {
  className?: string;
  parentTag?: string;
}
const GroupFlowTag = ({
  className,
  parentTag
}: Props) => {
  const group = useGroupConfig();
  return <div className={clsx(parentTag, "GroupFlowTag", className)} data-sentry-component="GroupFlowTag" data-sentry-source-file="GroupFlowTag.tsx">
      <div className="PoweredBy">Powered by</div>
      <a href={`https://www.groupflow.app/?utm_source=group-site&utm_medium=web&utm_content=footer&utm_term=${group.slug}`} rel="external">
        <GroupFlowWideLogo className="Logo" data-sentry-element="GroupFlowWideLogo" data-sentry-source-file="GroupFlowTag.tsx" />
      </a>
    </div>;
};
export default observer(GroupFlowTag);
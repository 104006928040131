interface Props {
  style?: any;
  className?: string;
}
export default function MegaphoneIcon(props: Props) {
  const {
    className,
    style
  } = props;
  const otherProps = ({} as any);
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32px" height="32px" style={style} className={className} title="Megaphone icon" {...otherProps} data-sentry-element="svg" data-sentry-component="MegaphoneIcon" data-sentry-source-file="MegaphoneIcon.tsx">
      <path d="M5,7.087a3,3,0,0,0-3,3v3.826a3,3,0,0,0,2,2.816V21a1,1,0,0,0,1,1H9a1,1,0,0,0,1-1V17.423l10.609,4.5A1,1,0,0,0,22,21V3a1,1,0,0,0-1.391-.921L8.8,7.087ZM8,20H6V16.913H8Zm0-5.087H5a1,1,0,0,1-1-1V10.087a1,1,0,0,1,1-1H8Zm2-6.164L20,4.51V19.49L10,15.251Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MegaphoneIcon.tsx" />
    </svg>;
}
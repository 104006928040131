import { SecondaryButton, DeleteButton } from "components/ui";
import SimpleModal from "components/SimpleModal";
import ApiErrors from "components/ApiErrors";
import { ApiResponse } from "types/api";
import { ThemeArea } from "types/theme";
import ModalHeading from "components/admin/ui/modal/ModalHeading";
interface Props {
  deleting?: boolean;
  deleteLabel?: string;
  deletionResponse?: ApiResponse<any>;
  onClose?: () => void;
  onDelete?: () => void;
  prompt?: string;
  themeArea?: ThemeArea;
}
export default function ConfirmDelete(props: Props) {
  const {
    deleting,
    deletionResponse,
    onClose,
    onDelete,
    prompt,
    themeArea
  } = props;
  return <SimpleModal isOpen={Boolean(deleting)} onClose={onClose} themeArea={props.themeArea} data-sentry-element="SimpleModal" data-sentry-component="ConfirmDelete" data-sentry-source-file="ConfirmDelete.tsx">
      <ModalHeading themeArea={themeArea} data-sentry-element="ModalHeading" data-sentry-source-file="ConfirmDelete.tsx">Delete Item</ModalHeading>

      <p className="p">
        {prompt || "Are you sure you want to delete this item?"}
      </p>

      <ApiErrors error={deletionResponse} data-sentry-element="ApiErrors" data-sentry-source-file="ConfirmDelete.tsx" />

      <div className="flex gap-4 justify-between mt-8">
        <SecondaryButton onClick={onClose} themeArea={themeArea} data-sentry-element="SecondaryButton" data-sentry-source-file="ConfirmDelete.tsx">
          Cancel
        </SecondaryButton>
        <DeleteButton onClick={onDelete} withIcon themeArea={themeArea} data-sentry-element="DeleteButton" data-sentry-source-file="ConfirmDelete.tsx">
          {props.deleteLabel || "Delete"}
        </DeleteButton>
      </div>
    </SimpleModal>;
}
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
interface Props {
  href: string;
}
export default function BackButton({
  href
}: Props) {
  return <Link href={href} legacyBehavior data-sentry-element="Link" data-sentry-component="BackButton" data-sentry-source-file="BackButton.tsx">
      <div className="rounded-full bg-white w-10 h-10 min-w-10 min-y-10 flex justify-center items-center border hover:border-secondary-300 text-secondary-500 hover:text-secondary-800 cursor-pointer">
        <ArrowLeftIcon className="w-6 h-6" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="BackButton.tsx" />
      </div>
    </Link>;
}
// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ApiErrors from "components/ApiErrors";
import { UsingMpForm } from "types/fieldEditing";
import { SubmitButtonRow } from ".";
interface BasicFormProps {
  children: React.ReactNode;
  usingHookForm: UsingMpForm;
}
export default function BasicForm(props: BasicFormProps) {
  const {
    children,
    usingHookForm
  } = props;
  const {
    handleSubmit,
    hookForm,
    saveError,
    saving
  } = usingHookForm;
  const isDirty = hookForm.formState.isDirty;
  return <form onSubmit={handleSubmit} data-sentry-component="BasicForm" data-sentry-source-file="BasicForm.tsx">
      {children}
      <ApiErrors error={saveError} data-sentry-element="ApiErrors" data-sentry-source-file="BasicForm.tsx" />
      <SubmitButtonRow disabled={!isDirty} saving={saving} withGroupFlow data-sentry-element="SubmitButtonRow" data-sentry-source-file="BasicForm.tsx" />
    </form>;
}
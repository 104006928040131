import { useRouter } from "next/router";
import { useEffect } from "react";
import {
  ContentItem,
  ContentItemDoubleExtended,
  ContentItemExtended,
} from "types/content";
import { GroupConfig } from "types/group";
import { useContentStore, useGroupConfig } from "./gf-app-context";
import nextConfigFn from "next/config";

export function textSubTypeTitle(subType: string | undefined | null) {
  if (subType === "application_letter") {
    return "Application Letter";
  } else if (subType === "block") {
    return "Block";
  } else if (subType === "page") {
    return "Page";
  } else if (subType === "post") {
    return "Post";
  } else {
    return undefined;
  }
}

export function findPageByUri(
  items: ContentItem[],
  uri: string
): ContentItem | undefined {
  return items.find((item) => item.subType === "page" && item.uri === uri);
}

export function findTextItemBySlug(
  items: ContentItem[],
  slug: string
): ContentItem | undefined {
  return items.find((item) => item.slug === slug);
}

export function useEditablePage({
  slug,
  uri,
  onLoad,
}: {
  slug?: string;
  uri?: string;
  onLoad?: (item: ContentItem | null) => void;
}) {
  if (!slug && !uri) {
    throw new Error("Either slug or uri must be set");
  }

  const contentStore = useContentStore();

  function loadPage(force?: boolean): void {
    contentStore.getTextItems({ force: force || false }).then((items) => {
      const page = getPage(items);
      if (page) {
        contentStore.registerCurrentPage(page.id, () => loadPage(true));
      }
      onLoad && onLoad(page);
    });
  }

  function getPage(items?: ContentItem[]): ContentItem | null {
    items = items || contentStore.textItems || undefined;
    if (!items) {
      return null;
    }

    if (slug) {
      return findTextItemBySlug(items, slug) || null;
    } else {
      return findPageByUri(items, uri as string) || null;
    }
  }

  useEffect(loadPage, []);

  return {
    loadPage,
    getPage,
  };
}

export function usePageUri() {
  const router = useRouter();
  if (!router) return null;
  const query = router.query || { storyPath: "" };
  let storyPath = query.storyPath || router.asPath || ("" as any); // TS getting confused
  storyPath = storyPath.replace(/\?.*/, "");
  return storyPath === "/" ? "home" : storyPath;
}

export function buildPostListItem(
  post: ContentItem,
  textItems: ContentItem[],
  navItems?: ContentItem[]
): ContentItemDoubleExtended {
  let textChildren = textItems.filter(
    (item: ContentItem) => item.parentId === post.id
  );
  let navChildren =
    navItems?.filter((item: ContentItem) => item.parentId === post.id) || [];
  let children = [...textChildren, ...navChildren];
  children = children.map((item) => {
    let children = textItems.filter((child) => child.parentId === item.id);
    const navChildren =
      navItems?.filter((nav) => nav.parentId === item.id) || [];
    children = [...children, ...navChildren];
    return { ...item, children };
  });
  return { ...post, children } as ContentItemDoubleExtended;
}

export function buildNavMenu(
  navItem: ContentItem,
  navItems: ContentItem[]
): ContentItemExtended {
  const children = navItems.filter((item) => item.parentId === navItem.id);
  return { ...navItem, children, author: null };
}

export const ALLOWED_CONTENT_URI_PREFIXES = [
  "/members/pages",
  "/resources",
  "/news",
  "/content",
];

export function isPresetTextItem(contentItem: ContentItem) {
  const { slug, subType, uri } = contentItem;
  if (subType === "page" && uri) {
    return !isCustomPageUri(uri);
  } else if (subType === "block" && slug === "board-election-form") {
    return true;
  } else {
    return false;
  }
}

export function isCustomPageUri(uri: string) {
  return ALLOWED_CONTENT_URI_PREFIXES.reduce((result, prefix) => {
    return result || uri.startsWith(prefix + "/");
  }, false);
}

export function makeContentItemExtended(
  contentItem: ContentItem
): ContentItemExtended {
  return { ...contentItem, author: null, children: [] };
}

export function pageTitle(
  title: string | null | undefined,
  groupConfig: GroupConfig
) {
  const config = nextConfigFn();
  const { hideBranding } = config.publicRuntimeConfig;
  const { abbreviation, name: groupName } = groupConfig;
  const globalTitle = hideBranding
    ? "Website"
    : `${groupName} (${abbreviation})`;
  return title ? `${title} - ${globalTitle}` : globalTitle;
}

export function usePageHeader() {
  const groupConfig = useGroupConfig();
  return {
    pageTitle: (title: string | null | undefined) => {
      return pageTitle(title, groupConfig);
    },
  };
}

export const baseContentItem: ContentItem = {
  baseType: "nav",
  label: "",
  content: "",
  contentType: "text/markdown",
  help: "",
  id: "",
  internalName: "",
  order: 0,
  parentId: "",
  photoId: null,
  plurality: "item",
  publishedAt: new Date(),
  purpose: null,
  rootUri: "",
  settings: {
    isAccountMenu: false,
    isLoggedInVisible: true,
    isLoggedOutVisible: true,
    isMobileVisible: true,
    role: null,
  },
  slug: "",
  status: "published",
  styles: [],
  subType: null,
  title: "",
  uri: "",
  active: true,
  children: [],
  photo: undefined,
};

export function scriptify(container: any) {
  let scripts: any[] = [];
  let loaded: boolean[] = [];
  container.childNodes.forEach((child: any) => {
    if (child.nodeName === "SCRIPT") {
      // Load scripts with a src first
      if (child.src) {
        const script = replaceScript(container, child);
        const index = loaded.length;
        loaded[index] = false;
        script.addEventListener("load", () => {
          loaded[index] = true;
          if (loaded.every((i) => i)) {
            replaceRemainingScripts();
          }
        });
      } else {
        scripts = [...scripts, child];
      }
    }
  });

  function replaceRemainingScripts() {
    scripts.forEach((script) => {
      replaceScript(container, script);
    });
  }
}

function replaceScript(container: any, child: any) {
  const newScript = document.createElement("script");
  newScript.appendChild(document.createTextNode(child.innerText));
  const attrs = [...child.attributes];
  attrs.forEach((attr) => {
    newScript.setAttribute(attr.name, child.getAttribute(attr.name));
  });

  container.replaceChild(newScript, child);
  return newScript;
}

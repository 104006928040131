import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Flash from "components/Flash";
import SaveResult from "components/form/SaveResult";
import { useApiCallParams, useContentEditStore, useContentStore, useMemberJwt } from "lib/gf-app-context";
import { buildResultFromApiResult } from "lib/mutation";
import { bulkUpdateContentItems, CLONE_CONTENT_ITEM, deleteContentItem, getContentItem, updateContentItem2 } from "lib/gf-api/content-api";
import { ContentBaseTypeEditMode, ContentItem, ContentItemExtended, ContentItemParams, MiniContentItem, NavigationState } from "types/content";
import { MutationResult } from "types/mutation";
import TextEdit from "./TextEdit";
import TextItemHeader from "./TextItemHeader";
import TextItemList from "./TextItemList";
import TextItemsSearch from "./TextItemsSearch";
import { Tab, TabContainer } from "components/tab";
import ListIcon from "components/icon/ListIcon";
import EditPropertiesIcon from "components/icon/EditPropertiesIcon";
import { useApiHelper } from "lib/gf-api/api-react";
import { ApiDataResponse, ApiResponse } from "types/api";
import { useMutation } from "@apollo/client";
import { apolloMutationResultToApiResponse } from "lib/gf-api/apollo";
import { ActionButton } from "components/form";
function TextManagement() {
  const contentEditStore = useContentEditStore();
  const contentStore = useContentStore();
  const jwt = useMemberJwt();
  const getCall = useApiHelper<ContentItemExtended>();
  const updateCall = useApiHelper<ContentItem>();
  const createCall = useApiHelper<ContentItem>();
  const apiError = getCall.errorResponse || updateCall.errorResponse || createCall.errorResponse;
  if (!jwt) return null;
  const apiProps = useApiCallParams();
  const [mutationResult, setMutationResult] = useState<undefined | MutationResult>();
  const navItem = contentEditStore?.currentNavItem();
  const contentItem = navItem?.contentItem;
  const mode = navItem?.baseTypeEditMode || "search";
  const [cloneContentItem] = useMutation<ContentItem>(CLONE_CONTENT_ITEM);
  function onExplicitClose() {
    clearResults();
    onClose();
  }
  async function onClose() {
    if (!contentEditStore) return;
    if (contentEditStore.navStack.length > 0) {
      contentEditStore?.popNavStack();
    }
    if (contentEditStore.navStack.length > 0) {
      loadCurrentItem();
    } else {
      contentEditStore.onEditingClose();
    }
  }
  function onSearchItemSelected(item: ContentItem) {
    contentEditStore?.pushStack({
      baseType: "text",
      contentId: item.id
    });
    loadCurrentItem(item.id);
  }
  async function onAfterSave(result: ApiResponse<ContentItem>) {
    if (!contentEditStore) return;
    if (result.error) {
      console.warn("Failed to update content item:", result);
      setMutationResult(buildResultFromApiResult(result, "Failed to save content item"));
      return false;
    } else {
      updateStateAfterSuccess(result);
    }
  }
  function updateStateAfterSuccess(result: ApiDataResponse<ContentItem>) {
    if (!contentEditStore) return;
    const updatedItem = result.data;
    if (updatedItem.subType === "post") {
      if (updatedItem.parentId) {
        // Post has parent (PostList)
        contentEditStore.popNavStack();
        reloadAfterMutation();
      } else if (updatedItem.plurality === "list") {
        // If PostList, reload and show parent
        const navItem = contentEditStore.currentNavItem();
        if (!navItem) return false;
        contentEditStore.updateCurrentNavItem({
          ...navItem,
          baseTypeEditMode: "list"
        });
        reloadAfterMutation(updatedItem.id);
      }
    } else {
      contentEditStore.updateCurrentNavItem({
        baseType: "text",
        baseTypeEditMode: "edit",
        contentId: updatedItem.id
      });
      reloadAfterMutation(updatedItem.id);
    }
    const subTypeName = updatedItem.subType === "post" ? "Post" : "Page";
    setMutationResult({
      type: "success",
      message: `Saved ${subTypeName}`
    });
    return true;
  }
  async function onRequestClonePostList(contentItem: ContentItemExtended) {
    const result = await cloneContentItem({
      variables: {
        id: contentItem.id
      }
    });
    const legacyResult = apolloMutationResultToApiResponse(result, "cloneContentItem");
    if (legacyResult.error) {
      console.warn("Failed to clone content item:", result);
      setMutationResult(buildResultFromApiResult(legacyResult, "Failed to clone content item"));
    } else {
      updateStateAfterSuccess(legacyResult);
    }
  }
  function clearResults() {
    setMutationResult(undefined);
    createCall.reset();
    updateCall.reset();
  }
  async function onRemoveChild(item: ContentItem): Promise<boolean> {
    clearResults();
    const params = {
      id: item.id,
      parentId: null
    };
    const result = await updateContentItem2(apiProps, params);
    if (result.error) {
      console.warn("Failed to update content item:", result);
      const mutationResult = buildResultFromApiResult(result, "Failed to update content type");
      setMutationResult(mutationResult);
      return false;
    } else {
      reloadAfterMutation();
      setMutationResult({
        type: "success",
        message: "Removed content item from list"
      });
      return true;
    }
  }
  function onAddExistingChild(item: ContentItem): Promise<boolean> {
    return new Promise(resolve => {
      if (!contentItem) return false;
      clearResults();
      const params = {
        id: item.id,
        parentId: contentItem.id
      };
      updateContentItem2(apiProps, params).then(result => {
        if (result.error) {
          console.warn("Failed to update content item:", result);
          const mutationResult = buildResultFromApiResult(result, "Failed to add child to list");
          setMutationResult(mutationResult);
          resolve(false);
        } else {
          reloadAfterMutation(result.data.id);
          setMutationResult({
            type: "success",
            message: "Added item to list"
          });
          resolve(true);
        }
      });
    });
  }
  function onDelete(): Promise<boolean> {
    if (!contentItem) {
      throw new Error("No contentItem set");
    }
    clearResults();
    return new Promise(resolve => {
      deleteContentItem(apiProps, contentItem.id).then(result => {
        if (!contentEditStore) {
          resolve(false);
          return;
        }
        if (result.error) {
          console.warn("Failed to delete content item:", result);
          const mutationResult = buildResultFromApiResult(result, "Failed to delete content type");
          setMutationResult(mutationResult);
          resolve(false);
        } else {
          contentEditStore.popNavStack();
          reloadAfterMutation();
          setMutationResult({
            type: "success",
            message: "Deleted content item"
          });
          resolve(true);
        }
      });
    });
  }
  function onNewContentClick() {
    if (!contentEditStore) return;
    const baseType = "text";
    clearResults();
    if (mode === "list") {
      const {
        subType,
        id: parentId
      } = contentItem || {};
      contentEditStore.pushStack({
        baseType,
        baseTypeEditMode: "add",
        initialParams: {
          baseType,
          parentId,
          subType,
          plurality: "item"
        }
      });
    } else {
      contentEditStore.pushStack({
        baseType,
        baseTypeEditMode: "add",
        initialParams: {
          baseType
        }
      });
    }
  }
  async function onReorderChildren(ids: string[]) {
    if (!contentItem) {
      console.warn("Expected contentItem");
    }
    clearResults();
    const paramsList = ids.map((id, order) => ({
      id,
      order
    }));
    const result = await bulkUpdateContentItems(apiProps, paramsList);
    if (result.error) {
      const mutationResult = buildResultFromApiResult(result, "Failed to reorder items");
      setMutationResult(mutationResult);
    } else {
      reloadAfterMutation();
      setMutationResult({
        type: "success",
        message: "Reordered items"
      });
    }
  }
  async function onChildSelected(item: ContentItem) {
    if (!contentEditStore) return;
    const baseTypeEditMode = item.plurality === "item" ? "edit" : "list";
    clearResults();
    const newNavItem: NavigationState = {
      baseType: "text",
      baseTypeEditMode,
      contentId: item.id
    };
    contentEditStore.pushStack(newNavItem);
    loadCurrentItem(item.id);
  }
  function loadContentItem(id: string) {
    return getCall.wrapCall(call => getContentItem(call, id));
  }
  async function reloadAfterMutation(contentItemId?: string) {
    loadCurrentItem(contentItemId);
    reloadCurrentPage(contentItemId);
  }
  async function loadCurrentItem(id?: string) {
    if (!contentEditStore) return;
    const navItem = contentEditStore.currentNavItem();
    if (!navItem) return;
    let contentId = id;
    if (!contentId) {
      contentId = navItem?.contentItem?.id || navItem?.contentId;
    }
    if (contentId) {
      const result = await loadContentItem(contentId);
      if (result && !result.error) {
        const contentItem = result.data;
        let baseTypeEditMode: ContentBaseTypeEditMode = contentItem.plurality === "item" ? "edit" : "list";
        baseTypeEditMode = navItem.baseTypeEditMode || baseTypeEditMode;
        const newNavItem = {
          ...navItem,
          contentItem,
          baseTypeEditMode
        };
        contentEditStore.updateCurrentNavItem(newNavItem);
      }
    }
  }
  function reloadCurrentPage(contentItemId?: string) {
    const id = contentItemId || contentItem?.id;
    id && contentStore.onPageUpdated(id);
  }
  function clearErrors() {
    getCall.reset();
    createCall.reset();
    updateCall.reset();
  }
  function showApiError() {
    if (!apiError) return null;
    return <Flash type="warning" onClose={() => clearErrors()} className="mt-4" data-sentry-element="Flash" data-sentry-component="showApiError" data-sentry-source-file="TextManagement.tsx">
        There was an error while servicing your request. Please try again.
      </Flash>;
  }
  function Wrapper({
    children,
    contentItem,
    heading,
    showResult,
    fullWidth
  }: {
    children: React.ReactNode;
    contentItem?: Partial<MiniContentItem>;
    heading?: string | React.ReactNode;
    showResult?: boolean;
    fullWidth?: boolean;
  }) {
    return <div style={fullWidth ? {} : {
      maxWidth: "500px"
    }} className="mx-auto" data-sentry-component="Wrapper" data-sentry-source-file="TextManagement.tsx">
        <TextItemHeader onBackClick={onExplicitClose} contentItem={contentItem} heading={heading} data-sentry-element="TextItemHeader" data-sentry-source-file="TextManagement.tsx" />
        {showApiError()}
        {showResult === false ? null : <SaveResult mutationResult={mutationResult} className="mb-4" />}
        {children}
      </div>;
  }
  useEffect(() => {
    const contentId = navItem?.contentId;
    if (!contentItem && contentId) {
      loadCurrentItem();
    }
  }, [navItem?.contentId]);
  if (mode === "add") {
    const initialParams = navItem?.initialParams || {};
    const addingParams: Partial<ContentItemParams> = initialParams;
    return <Wrapper showResult={false} contentItem={addingParams} fullWidth>
        <TextEdit addingAttrs={addingParams} mutationResult={mutationResult} onClose={onExplicitClose} onAfterSave={onAfterSave} saving={createCall.processing} />
      </Wrapper>;
  } else if (mode === "edit") {
    return <Wrapper showResult={false} contentItem={contentItem} fullWidth>
        <TextEdit contentItem={contentItem} mutationResult={mutationResult} onClose={onExplicitClose} onRequestClonePostList={onRequestClonePostList} onDelete={onDelete} onAfterSave={onAfterSave} saving={updateCall.processing} />
      </Wrapper>;
  } else if (mode === "list") {
    const resetMessages = () => null;
    const onModeChange = (mode: any) => {
      if (!contentEditStore) return;
      if (mode === "edit") {
        if (!navItem) return;
        contentEditStore.updateCurrentNavItem({
          ...navItem,
          baseTypeEditMode: "edit"
        });
      }
    };
    const mode: any = "list";
    return <Wrapper contentItem={contentItem}>
        <TabContainer className="mb-2">
          {contentItem?.plurality !== "item" && <Tab onClick={() => {
          resetMessages();
          onModeChange("list");
        }} active={mode === "list"} className="flex flex-row gap-1 items-center text-gray-darker">
              <ListIcon className="fill-current" style={{
            width: "18px",
            height: "18px",
            marginLeft: "-2px"
          }} />
              <div>List</div>
            </Tab>}
          <Tab onClick={() => {
          resetMessages();
          onModeChange("edit");
        }} active={mode === "edit"} className="flex flex-row gap-1 items-center">
            <EditPropertiesIcon className="fill-current text-gray-darker" style={{
            width: "18px",
            height: "18px",
            marginLeft: "-2px"
          }} />
            Attributes
          </Tab>
        </TabContainer>
        {contentItem && <TextItemList contentItem={contentItem} onAddExistingChild={onAddExistingChild} onItemSelected={onChildSelected} onNewChildClick={onNewContentClick} onItemRemoveRequested={onRemoveChild} onReorder={onReorderChildren} />}
      </Wrapper>;
  }
  const searchHeading = <>
      <div>Search content</div>
      <ActionButton onClick={onNewContentClick} className="text-base">
        Add Content
      </ActionButton>
    </>;
  return <Wrapper heading={searchHeading} data-sentry-element="Wrapper" data-sentry-component="TextManagement" data-sentry-source-file="TextManagement.tsx">
      <TextItemsSearch onItemSelected={onSearchItemSelected} data-sentry-element="TextItemsSearch" data-sentry-source-file="TextManagement.tsx" />
    </Wrapper>;
}
export default observer(TextManagement);
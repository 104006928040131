// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import Flash from "components/Flash";
import { ActionButton, ErrorMessage, TextInput } from "components/form";
import { useApiCallParams } from "lib/gf-app-context";
import { updateAccountCredentials } from "lib/gf-api";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
interface Props {
  jwt: string;
  onSubmitted?: () => void;
  returnToUri?: string;
}
export default function VerifyEmail(props: Props) {
  const {
    jwt
  } = props;
  const [formStatus, setFormStatus]: any = useState("new");
  const apiCall = {
    ...useApiCallParams(),
    jwt
  };
  const [error, setError]: [string | undefined, (v: string | undefined) => void] = useState();
  const form = useForm<{
    email: string;
  }>();
  const router = useRouter();
  const returnToUri = props.returnToUri || router.asPath;
  function onSubmit({
    email
  }: {
    email: string;
  }) {
    if (email) {
      setFormStatus("submitting");
      const params = {
        unconfirmedEmail: email,
        returnToUri
      };
      updateAccountCredentials(apiCall, params).then(result => {
        if (result.error) {
          setError("Failed to service your request.");
          setFormStatus("new");
        } else {
          setFormStatus("submitted");
          setError(undefined);
          props.onSubmitted && props.onSubmitted();
        }
      });
    }
  }
  return <>
      {formStatus !== "submitted" ? <form onSubmit={form.handleSubmit(onSubmit)}>
          <p className="p">Please enter your email address:</p>
          {formStatus === "submitting" ? <p className="p">Submitting...</p> : null}
          {formStatus === "new" ? <>
              <TextInput type="email" register={form.register("email", {
          required: true,
          pattern: /^\S+@\S+\.\w+$/
        })} className="max-w-xs" style={{
          display: "inline-block"
        }} />
              <ErrorMessage errors={form.formState.errors} name="email" messages={{
          pattern: "This does not appear to be an email address"
        }} />
              <ActionButton className="ml-2" type="submit">
                Next
              </ActionButton>
            </> : null}
        </form> : null}

      {error && <Flash type="warning">{error}</Flash>}

      {formStatus === "submitted" ? <Flash>
          A confirmation email has been sent. Please check your email.
        </Flash> : null}
    </>;
}
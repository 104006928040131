import { useContext } from "react";
import { GfAppContext } from "lib/gf-app-context";
import { Me } from "types/member";
import MemberPhoto from "components/member/MemberPhoto";
import { observer } from "mobx-react-lite";
import { memberCanSeeAdmin } from "lib/auth";
import { ArrowRightOnRectangleIcon, BanknotesIcon, Cog6ToothIcon, ListBulletIcon, UserCircleIcon, UserIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import LinkMenuItem from "components/ui/LinkMenuItem";
interface Props {
  menuIsInline?: boolean;
}
function AccountDropdown({
  menuIsInline
}: Props) {
  const appContext = useContext(GfAppContext);
  const {
    userStore
  } = appContext;
  const user = userStore && userStore.user;
  function avatarRow(user: Me) {
    const {
      photo
    } = user.profile;
    const userPhotoUrl = photo?.url;
    return <div {...{
      className: "flex my-2 px-2 text-gray-darkest"
    }} data-sentry-component="avatarRow" data-sentry-source-file="AccountDropdown.tsx">
        {userPhotoUrl && <img src={userPhotoUrl} alt="Profile and account menu" className="w-10 h-10 rounded-full mr-3" />}
        {!userPhotoUrl && <UserCircleIcon className="w-10 h-10 text-gray-dark" />}
        <div className="flex flex-col justify-center">
          <div>{user.account.name}</div>
          {user.account.status !== "BANNED" && user?.profile?.title && <div className="text-gray-dark">{user.profile.title}</div>}
        </div>
      </div>;
  }
  function navItems(menuIsInline?: boolean) {
    const showAdmin = memberCanSeeAdmin(user?.account);
    return <>
        <LinkMenuItem href="/members/profile" icon={<UserIcon className="size-4" />} label="Profile" menuIsInline={menuIsInline} data-sentry-element="LinkMenuItem" data-sentry-source-file="AccountDropdown.tsx" />
        <LinkMenuItem href="/members/subscriptions" label="Notification Settings" icon={<ListBulletIcon className="size-4" />} menuIsInline={menuIsInline} data-sentry-element="LinkMenuItem" data-sentry-source-file="AccountDropdown.tsx" />
        <LinkMenuItem href="/members/dues" label="Dues" icon={<BanknotesIcon className="size-4" />} menuIsInline={menuIsInline} data-sentry-element="LinkMenuItem" data-sentry-source-file="AccountDropdown.tsx" />
        <LinkMenuItem href="/members/account" label="Account" icon={<Cog6ToothIcon className="size-4" />} menuIsInline={menuIsInline} data-sentry-element="LinkMenuItem" data-sentry-source-file="AccountDropdown.tsx" />
        {showAdmin && <LinkMenuItem href="/_admin" label="Admin" icon={<WrenchScrewdriverIcon className="size-4" />} menuIsInline={menuIsInline} />}
        <div className="border-b my-1" />
        <LinkMenuItem href="/logout" label="Log Out" icon={<ArrowRightOnRectangleIcon className="size-4" />} menuIsInline={menuIsInline} data-sentry-element="LinkMenuItem" data-sentry-source-file="AccountDropdown.tsx" />
      </>;
  }
  if (!user) {
    return null;
  }
  if (menuIsInline) {
    return <div>
        <div className="border-b my-1" />
        {avatarRow(user)}
        <div className="border-b my-1" />
        {navItems(menuIsInline)}
      </div>;
  }
  return <Menu data-sentry-element="Menu" data-sentry-component="AccountDropdown" data-sentry-source-file="AccountDropdown.tsx">
      <div>
        <MenuButton className="AccountDropdown w-10 flex items-center hover:brightness-110 transition text-sm" style={{
        position: "relative"
      }} data-sentry-element="MenuButton" data-sentry-source-file="AccountDropdown.tsx">
          {user.profile?.photo?.url && <MemberPhoto photoUrl={user.profile.photo?.url} photoSize={40} />}
          {!user.profile?.photo?.url && <UserCircleIcon className="w-10 h-10 text-gray-dark" />}
        </MenuButton>
        <MenuItems anchor="bottom" className="gf-customer bg-white text-sm text-black border shadow-lg rounded-lg pb-1" data-sentry-element="MenuItems" data-sentry-source-file="AccountDropdown.tsx">
          {avatarRow(user)}
          <div className="border-b my-1" />
          {navItems()}
        </MenuItems>
      </div>
    </Menu>;
}
export default observer(AccountDropdown);
import { ErrorMessage, SubmitButtonRow, TextInput } from "components/form";
import RowLabel from "components/profileEdit/RowLabel";
import { useHookFormAutoFocus } from "hooks/useHookFormAutoFocus";
import { EMAIL_PATTERN } from "lib/auth";
import { UseFormReturn } from "react-hook-form";
interface EmailForm {
  email: string;
  password: string;
}
interface Props {
  form: UseFormReturn<any>;
  onCancel?: () => void;
  onSubmit?: () => void;
}
export default function EmailPrompt(props: Props) {
  const form = (props.form as UseFormReturn<EmailForm>);
  const {
    formState: {
      errors
    },
    register
  } = form;
  const wrapRegister = useHookFormAutoFocus();
  async function handleSubmit(e: React.SyntheticEvent) {
    if (!props.onSubmit) {
      return;
    }
    e.preventDefault();
    const isValid = await form.trigger("email");
    if (!isValid) {
      return;
    }
    props.onSubmit();
  }
  return <>
      <div>
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="EmailPrompt.tsx">Email address</RowLabel>
        <TextInput className="block" type="email" register={wrapRegister(register("email", {
        required: true,
        pattern: EMAIL_PATTERN
      }))} onKeyDown={async e => {
        // Call onSubmit when the user presses Enter
        if (e.key === "Enter") {
          handleSubmit(e);
        }
      }} placeholder="you@example.com" data-sentry-element="TextInput" data-sentry-source-file="EmailPrompt.tsx" />
        <ErrorMessage name="email" errors={errors} messages={{
        pattern: "email must be a valid email address"
      }} data-sentry-element="ErrorMessage" data-sentry-source-file="EmailPrompt.tsx" />
      </div>
      <SubmitButtonRow cancel={props.onCancel && {
      onClick: props.onCancel
    }} onClick={handleSubmit} data-sentry-element="SubmitButtonRow" data-sentry-source-file="EmailPrompt.tsx">
        Next
      </SubmitButtonRow>
    </>;
}
import SimpleModal from "components/SimpleModal";
import { useAshMutation } from "hooks/ash";
import { UPDATE_SELF_MEMBER2, VERIFY_MEMBER_AUTH_CODE } from "lib/gf-api/member-api";
import { useEmailSetModalStore, useMember, useUserStore } from "lib/gf-app-context";
import Style from "lib/Style";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EmailSetForm } from "types/account";
import PromptEmailAddress from "./PromptEmailAddress";
import PromptAuthCode from "./PromptAuthCode";
import { TinySelfMember } from "types/member";
function EmailSetPrompt() {
  const member = useMember();
  const store = useEmailSetModalStore();
  const form = useForm<EmailSetForm>({
    defaultValues: {
      authCode: "",
      email: ""
    }
  });
  const {
    handleSubmit
  } = form;
  const [updateAccount, updateAccountState] = useAshMutation(UPDATE_SELF_MEMBER2);
  const userStore = useUserStore();
  const [screen, setScreen] = useState<"initializing" | "email" | "authCode">(member ? "email" : "initializing");
  const [verifyMemberAuthCode, verifyMemberAuthCodeState] = useAshMutation<TinySelfMember>(VERIFY_MEMBER_AUTH_CODE);
  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null);
  useEffect(() => {
    if (store && member && !member.account.email) {
      store.open();
    }
  }, [member?.account?.email]);
  async function onSubmit(data: EmailSetForm) {
    if (screen === "email") {
      onSubmitEmail(data);
    } else if (screen === "authCode") {
      onSubmitAuthCode(data);
    }
  }
  async function onSubmitEmail(data: EmailSetForm) {
    const email = data.email;
    if (!email) throw new Error("Email is required");
    setSubmittedEmail(email);
    const input = {
      unconfirmedEmail: email
    };
    const resp = await updateAccount({
      variables: {
        input
      }
    }, "updateSelfMember2");
    if (!resp.hasError) {
      userStore.loadUser();
      form.reset();
      setScreen("authCode");
    }
  }
  async function onSubmitAuthCode(data: EmailSetForm) {
    const authCode = data.authCode;
    if (!authCode) throw new Error("Auth code is required");
    const input = {
      authCode,
      unconfirmedEmail: submittedEmail
    };
    const resp = await verifyMemberAuthCode({
      variables: {
        input
      }
    }, "verifyMemberAuthCode");
    if (!resp.hasError) {
      userStore.loadUser();
      store?.close();
    }
  }
  useEffect(() => {
    if (member && screen === "initializing") {
      setScreen("email");
    }
  }, [member]);
  return <SimpleModal isOpen={store?.isOpen} showCloseButton={false} data-sentry-element="SimpleModal" data-sentry-component="EmailSetPrompt" data-sentry-source-file="EmailSetPrompt.tsx">
      <form className={Style.formClass} onSubmit={handleSubmit(onSubmit)}>
        {screen === "email" && <PromptEmailAddress form={form} originalEmail={member?.account?.email} mutationState={updateAccountState} />}
        {screen === "authCode" && submittedEmail && <PromptAuthCode form={form} email={submittedEmail} mutationState={verifyMemberAuthCodeState} onCancelRequest={() => setScreen("email")} />}
      </form>
    </SimpleModal>;
}
export default observer(EmailSetPrompt);
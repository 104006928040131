import { observer } from "mobx-react-lite";
import { useLoginModalStore, useMember } from "lib/gf-app-context";
import { ActionButton } from "components/form";
interface Props {
  introText?: string;
}
function LoginButton(props: Props) {
  const {
    introText
  } = props;
  const loginModalStore = useLoginModalStore();
  const user = useMember();
  if (user || !loginModalStore) {
    return null;
  }
  return <>
      {introText && <div>{introText}</div>}
      <ActionButton onClick={() => loginModalStore.open()} data-sentry-element="ActionButton" data-sentry-source-file="LoginButton.tsx">
        Log in now
      </ActionButton>
    </>;
}
export default observer(LoginButton);
import Color from "color";
import { observer } from "mobx-react-lite";
import Head from "next/head";
import { GroupConfig } from "types/group";
interface Props {
  group: GroupConfig;
}

// Generates CSS styles based on the group's configuration
function ThemeStyle({
  group
}: Props) {
  const accentColor = group.webSite?.config.accentColor || "#333333";
  const textColorOverAccent = group.webSite?.config.textColorOverAccent || "#FFFFFF";
  const accentObj = Color(accentColor);
  const accentLighter = accentObj.mix(Color("white"), 0.6).hex();
  const accentUltralight = accentObj.mix(Color("white"), 0.9).hex();
  const accentDarker = accentObj.mix(Color("black"), 0.5).hex();
  const uiBackgroundColor = group.webSite?.config.uiBackgroundColor || "#FFFFFF";
  let buttonBorderRadius = "100px";
  const cornerStyle = group.webSite?.config.cornerStyle;
  if (cornerStyle === "SQUARE") {
    buttonBorderRadius = "0px";
  } else if (cornerStyle === "ROUNDED") {
    buttonBorderRadius = "0.375rem";
  }
  return <Head data-sentry-element="Head" data-sentry-component="ThemeStyle" data-sentry-source-file="ThemeStyle.tsx">
      <style id="theme-style">{`
        :root {
          --group-accent: ${accentColor};
          --group-accent-lighter: ${accentLighter};
          --group-accent-ultralight: ${accentUltralight};
          --group-accent-darker: ${accentDarker};
          --group-text-over-accent: ${textColorOverAccent};
          --group-button-radius: ${buttonBorderRadius};
          --group-ui-background: ${uiBackgroundColor};
        }
      `}</style>
    </Head>;
}
export default observer(ThemeStyle);
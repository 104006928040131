import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import ModalHeading from "components/admin/ui/modal/ModalHeading";
import AshMutationErrors from "components/alerts/AshMutationErrors";
import { ErrorMessage, FormRow, RowLabel, SubmitButtonRow, TextInput } from "components/form";
import { useHookFormAutoFocus } from "hooks/useHookFormAutoFocus";
import { UseFormReturn } from "react-hook-form";
import { EmailSetForm } from "types/account";
import { MutationInfo } from "types/ash";
interface Props {
  email: string;
  form: UseFormReturn<EmailSetForm>;
  onCancelRequest?: () => void;
  mutationState: MutationInfo<any>;
}
export default function PromptAuthCode(props: Props) {
  const {
    email,
    form,
    mutationState
  } = props;
  const {
    formState,
    register
  } = form;
  const wrapRegister = useHookFormAutoFocus();
  if (!email) {
    throw new Error("Email is required");
  }
  function onCancelClick() {
    props.onCancelRequest?.();
  }
  return <>
      <ModalHeading data-sentry-element="ModalHeading" data-sentry-source-file="PromptAuthCode.tsx">Enter the confirmation code</ModalHeading>
      <p>
        We sent a code to your email address at{" "}
        <b className="font-bold">{email}</b>.
      </p>
      <FormRow data-sentry-element="FormRow" data-sentry-source-file="PromptAuthCode.tsx">
        <RowLabel data-sentry-element="RowLabel" data-sentry-source-file="PromptAuthCode.tsx">Enter the code from the email:</RowLabel>
        <TextInput register={wrapRegister(register("authCode", {
        required: true,
        pattern: /^\d{6}$/
      }))} placeholder="999999" data-sentry-element="TextInput" data-sentry-source-file="PromptAuthCode.tsx" />
        <ErrorMessage errors={formState.errors} name="authCode" messages={{
        pattern: "Invalid auth code. Please enter the 6-digit code."
      }} data-sentry-element="ErrorMessage" data-sentry-source-file="PromptAuthCode.tsx" />
        <AshMutationErrors mutationInfo={mutationState} data-sentry-element="AshMutationErrors" data-sentry-source-file="PromptAuthCode.tsx" />
      </FormRow>
      <SubmitButtonRow cancel={{
      onClick: onCancelClick,
      label: <>
              <ArrowLeftIcon className="w-4 h-4" />
              Back
            </>
    }} data-sentry-element="SubmitButtonRow" data-sentry-source-file="PromptAuthCode.tsx">
        Confirm
      </SubmitButtonRow>
    </>;
}
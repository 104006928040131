/**
 * Page sections for a web page, including Header, Secondary Nav, Main, and Footer.
 *
 * This is usually called from PageLayout.
 * If `path` is provided, it will render the page at that path.
 */

import SecondaryNavbar from "components/layout/SecondaryNavbar";
import { buildSiteContext } from "components/webComponent";
import { resolveRoute } from "lib/web-component";
import { ReactNode, useEffect, useState } from "react";
import { LayoutConfig } from "types/page";
interface Props {
  children?: ReactNode;
  loggedIn?: boolean;
  path?: string;
  layoutConfig?: LayoutConfig;
}
const context = buildSiteContext();
export default function PageSections(props: Props) {
  const [siteExports, setSiteExports] = useState<any>();
  const {
    initializeWebSite
  } = siteExports || {};
  initializeWebSite && initializeWebSite(context);
  function getSiteExports() {
    // groupFlowExports are defined in a <script> tag added in pages/_document.tsx
    const gfExports = (window as any).groupFlowExports;
    setSiteExports(gfExports);
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      getSiteExports();
    }
  }, []);
  return page({
    path: props.path,
    children: props.children,
    loggedIn: props.loggedIn,
    siteExports,
    layoutConfig: props.layoutConfig
  });
}
function page(props: {
  path: string | undefined;
  children: ReactNode | undefined;
  loggedIn: boolean | undefined;
  siteExports: any;
  layoutConfig?: LayoutConfig;
}) {
  const {
    path,
    children,
    loggedIn,
    siteExports
  } = props;
  let Page;
  const {
    Components,
    ComponentsLookup,
    ROUTES
  } = siteExports || {};

  // Convert ROUTES to an array of objects, with the `route` property added.
  const routes = Object.entries(ROUTES || {}).map(([route, info]: [string, any]) => ({
    ...info,
    route
  }));
  if (!ROUTES || !Components || !ComponentsLookup) {
    return null;
  }
  const routedInfo = path && resolveRoute(path, routes);
  const showSecondaryNav = typeof props.layoutConfig?.showSecondaryNav === "undefined" ? true : props.layoutConfig?.showSecondaryNav;
  if (routedInfo && routedInfo.route !== "/members/*" && routedInfo.route !== "/_admin/*") {
    Page = Components[routedInfo.name];
    return Page && <Page context={context} children={children} />;
  } else if (path?.startsWith("/_admin")) {
    return children;
  } else if (loggedIn && (path?.startsWith("/members") || path?.startsWith("/events"))) {
    const headerName = ComponentsLookup["members-area-header"];
    const footerName = ComponentsLookup["default-footer"];
    let Header = headerName && Components[headerName] || null;
    let Footer = footerName && Components[footerName] || null;
    let secondaryNavBar: ReactNode = <SecondaryNavbar className="py-4" />;
    const showFooter = typeof props.layoutConfig?.showFooter === "undefined" ? true : props.layoutConfig?.showFooter;
    return <>
        {Header && <Header />}
        {showSecondaryNav && secondaryNavBar}
        {children}
        {showFooter && Footer && <Footer />}
      </>;
  } else {
    let headerName = ComponentsLookup["default-header"];
    const footerName = ComponentsLookup["default-footer"];
    let Header = headerName && Components[headerName] || null;
    let Footer = footerName && Components[footerName] || null;
    let secondaryNavBar: ReactNode = <SecondaryNavbar className="py-4" />;
    return <>
        {Header && <Header />}
        {showSecondaryNav && secondaryNavBar}
        {children}
        {Footer && <Footer />}
      </>;
  }
}
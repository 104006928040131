// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { createContext, useContext, useEffect, useState } from "react";
import AppContextType from "types/gf-app-context";
import { Me, Role } from "types/member";
import ChannelStore from "stores/ChannelStore";
import { useCookies } from "react-cookie";
import { jwtCookieName } from "lib/constants";
import { useRouter } from "next/router";
import UserStore from "stores/UserStore";
import ContentEditStore from "stores/ContentEditStore";
import { MeetupConfig, MeetupIntegration, GroupConfig } from "types/group";
import {
  getApiBaseUrl,
  getBackendBaseUrl,
  getRootUrl,
} from "./gf-api/api-util";
import { URI } from "types/web";
import ContentStore from "stores/ContentStore";
import { memberHasRoleAccess } from "./auth";
import { AuthErrorType, AuthResult } from "types/auth";
import { ApiCall } from "types/api";
import HamburgerStore from "stores/HamburgerStore";
import { isWebSite2 } from "./web-component";
import AlertStore from "stores/AlertStore";
import StripeStore from "stores/StripeStore";
import FormStore from "stores/FormStore";
import LoginModalStore from "stores/LoginModalStore";
import PasswordSetModalStore from "stores/PasswordSetModalStore";
import EmailSetModalStore from "stores/EmailSetModalStore";

export const GfAppContext = createContext<Partial<AppContextType>>({});

export function useAppContext(): Partial<AppContextType> {
  return useContext(GfAppContext);
}

export function useContentStore(): ContentStore {
  const { contentStore } = useAppContext();
  return contentStore as ContentStore;
}

export function useContentEditStore(): ContentEditStore | null {
  const { contentEditStore } = useAppContext();
  return contentEditStore ?? null;
}

export function useFormStore(): FormStore {
  const { formStore } = useAppContext();
  return formStore as FormStore;
}

export function useApiBaseUrl(): string {
  const uri = useURI();
  return getApiBaseUrl(uri);
}

export function useWebBaseUrl() {
  const uri = useURI();
  return getRootUrl(uri);
}

export function useBackendBaseUrl() {
  const uri = useURI();
  return getBackendBaseUrl(uri);
}

export function useNavItemUrl(itemUri: string) {
  const uri = useURI();
  const backendBaseUrl = getBackendBaseUrl(uri);

  if (itemUri.match(/^https?:/)) {
    return itemUri;
  }

  return itemUri.replace("{backendBaseUrl}", backendBaseUrl);
}

export function useURI(): URI {
  const { uri } = useAppContext();
  if (!uri) throw new Error("uri is not set");
  return uri;
}

export function useMeetupOAuthUrl(referrer: string) {
  const group = useGroupConfig();
  const backendBaseUrl = useBackendBaseUrl();
  return (
    backendBaseUrl +
    `/browser/oauth-redirect?group=${group.slug}&referrer=${encodeURIComponent(
      referrer
    )}`
  );
}

export function useJwtStore() {
  const { jwtStore } = useAppContext();
  if (!jwtStore) {
    throw new Error("No JwtStore present");
  }
  return jwtStore;
}

export function useMemberJwt(): string | null {
  const { jwtStore } = useAppContext();
  return jwtStore?.memberJwt || null;
}

export function useAnyValidJwt(): string | null {
  const { jwtStore } = useAppContext();
  return jwtStore?.validJwt || null;
}

export function useUserId(): string | null {
  const member = useMember();
  return member?.account.id || null;
}

export function useMember(): Me | null {
  const userStore = useUserStore();
  return userStore?.user || null;
}

export function useUserStore(): UserStore {
  const appContext = useContext(GfAppContext);
  const { userStore } = appContext;
  return userStore as UserStore;
}

export function useStripeStore(): StripeStore {
  const appContext = useContext(GfAppContext);
  const { stripeStore } = appContext;
  if (!stripeStore) throw new Error("stripeStore not set");
  return stripeStore as StripeStore;
}

export function useChannelStore(): ChannelStore | null {
  const appContext = useContext(GfAppContext);
  return appContext.channelStore || null;
}

export function useAlertStore(): AlertStore {
  const appContext = useContext(GfAppContext);
  const { alertStore } = appContext;
  if (!alertStore) throw new Error("alertStore not set");
  return alertStore as AlertStore;
}

export function useHamburgerStore(): HamburgerStore | null {
  const appContext = useContext(GfAppContext);
  return appContext.hamburgerStore || null;
}

export function useLoginModalStore(): LoginModalStore | null {
  const appContext = useContext(GfAppContext);
  return appContext.loginModalStore ?? null;
}

export function usePasswordSetModalStore(): PasswordSetModalStore | null {
  const appContext = useContext(GfAppContext);
  return appContext.passwordSetModalStore ?? null;
}

export function useEmailSetModalStore(): EmailSetModalStore | null {
  const appContext = useContext(GfAppContext);
  return appContext.emailSetModalStore ?? null;
}

export function useMeetupConfig(): MeetupConfig | undefined {
  const { integrations } = useGroupConfig();

  const meetupIntegration = integrations.find((i) => i.name === "meetup") as
    | MeetupIntegration
    | undefined;

  if (!meetupIntegration) {
    return undefined;
  }

  return meetupIntegration.config;
}

export function useGroupConfig(): GroupConfig {
  const appContext = useAppContext();
  const { groupConfig } = appContext;
  if (!groupConfig) throw new Error("groupConfig not set");
  return groupConfig;
}

export function useEventTagGroups() {
  const group = useGroupConfig();
  return group.tagGroups.filter((tg) => tg.purpose === "EVENT");
}

export function useApiCallParams(groupSlug?: string): ApiCall {
  const appContext = useAppContext();
  const { sessionId } = appContext;
  groupSlug = groupSlug || appContext.groupSlug;
  if (!groupSlug) throw new Error("useApiCallParams() groupSlug is not set");
  const jwt = useAnyValidJwt();
  const baseUrl = useApiBaseUrl();
  return { baseUrl, groupSlug, jwt, sessionId };
}

export function useRemoveJwtCookie() {
  const cookiesUse = useCookies([jwtCookieName]);
  const removeCookie = cookiesUse[2];
  return () => {
    removeCookie(jwtCookieName);
    if (typeof document !== "undefined") {
      document.cookie = `${jwtCookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  };
}

export function useRequireMemberToContinue(props?: { redirect?: boolean }) {
  const { redirect } = props || {};
  const router = useRouter();
  const { jwtStore } = useAppContext();
  const jwt = jwtStore?.memberJwt;

  useEffect(() => {
    if (redirect !== false && !jwt) {
      router.push(`/login?ret=${encodeURIComponent(router.asPath)}`);
    }
  }, [false]);

  return jwt;
}

export function useValidateUserRole(role: Role): AuthResult {
  const { jwtStore } = useAppContext();
  const jwt = jwtStore?.memberJwt;
  const user = useMember();
  const hasAccess = user ? memberHasRoleAccess(user.account, role) : false;
  const router = useRouter();
  let type: undefined | AuthErrorType;
  if (!jwt) {
    type = "unauthenticated";
  } else if (!user) {
    type = "loading";
  } else if (!hasAccess) {
    type = "unauthorized";
  }
  const [state, setState] = useState<AuthResult>({
    valid: !type,
    type: type ?? null,
  });

  useEffect(() => {
    if (type === "unauthenticated") {
      router.push(`/login?ret=${encodeURIComponent(router.asPath)}`);
    }
    const newState: AuthResult = { valid: !type, type: type ?? null };
    setState(newState);
  }, [type]);

  return state;
}

// Calls useEffect(), but only calls the callback when the user is authorized.
export function useAuthorizedEffect(
  callback: () => void,
  authResult: AuthResult,
  changeFlags?: any[]
) {
  changeFlags = [authResult.valid, ...(changeFlags || [])];
  useEffect(() => {
    if (authResult.valid) callback();
  }, changeFlags);
}

type EngineType = "themed" | "web-components";
interface PageEngine {
  type: EngineType;
  version: number;
}

export function usePageEngine(): PageEngine {
  const groupConfig = useGroupConfig();
  const isSite2 = isWebSite2(groupConfig);
  let type: EngineType = "themed";
  let version = 1;

  if (isSite2) {
    type = "web-components";
    version = 2;
  } else {
    throw new Error(
      `Engine type not detected for groupSlug=${groupConfig.slug}`
    );
  }

  return { type, version };
}

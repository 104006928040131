"use client";

// UIs that appear globally in the application, such SideCar menu, login modal, etc.
import LoginModal from "components/login/LoginModal";
import SideCarMenu from "./SideCarMenu";
import PasswordSetPrompt from "components/member/PasswordSetPrompt";
import ContentEditing from "components/contentEditing/ContentEditing";
import GroupSwitcher from "components/dev/GroupSwitcher";
import SupportChat from "components/support/SupportChat";
import EmailSetPrompt from "components/member/emailSet/EmailSetPrompt";
import SplashScreen from "./BrandLoading";
export function GlobalUIs() {
  return <>
      <SplashScreen data-sentry-element="SplashScreen" data-sentry-source-file="GlobalUIs.tsx" />
      <SideCarMenu data-sentry-element="SideCarMenu" data-sentry-source-file="GlobalUIs.tsx" />
      <LoginModal data-sentry-element="LoginModal" data-sentry-source-file="GlobalUIs.tsx" />
      <PasswordSetPrompt data-sentry-element="PasswordSetPrompt" data-sentry-source-file="GlobalUIs.tsx" />
      <EmailSetPrompt data-sentry-element="EmailSetPrompt" data-sentry-source-file="GlobalUIs.tsx" />
      <GroupSwitcher data-sentry-element="GroupSwitcher" data-sentry-source-file="GlobalUIs.tsx" />
      <div className="LowerRightControls flex flex-col gap-1 fixed z-20 bottom-4 right-4 md:right-6 m-1 font-montserrat">
        <ContentEditing data-sentry-element="ContentEditing" data-sentry-source-file="GlobalUIs.tsx" />
        <SupportChat data-sentry-element="SupportChat" data-sentry-source-file="GlobalUIs.tsx" />
      </div>
    </>;
}
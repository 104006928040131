import { ActionButton } from "components/form";
import TrashIcon from "components/icon/TrashIcon";
import { ThemeArea } from "types/theme";
interface Props {
  admin?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  roundedClass?: string;
  size?: "small" | "medium";
  themeArea?: ThemeArea;
  withIcon?: boolean;
}
export default function DeleteButton(props: Props) {
  let {
    admin,
    className,
    children,
    disabled,
    onClick,
    size,
    themeArea,
    withIcon
  } = props;
  let bgColor = "bg-red-dark";
  let borderColor = "border-red-dark";
  let hoverBgColor = "";
  let textColor = "text-white";
  if (disabled) {
    bgColor = "bg-gray";
    borderColor = "border-gray";
    textColor = "text-gray-dark";
  } else {
    hoverBgColor = "hover:bg-red-danger";
  }
  let padding = "py-1 px-3";
  let textSize = "";
  let trashStyle = {
    width: "20px",
    height: "20px"
  };
  let itemsGap = "gap-2";
  if (size === "small") {
    textSize = "text-sm";
    trashStyle = {
      width: "16px",
      height: "16px"
    };
    padding = "px-2";
    itemsGap = "gap-1";
  }
  const cornerRadius = admin ? "rounded-full" : "rounded-[var(--group-button-radius)]";
  className = `${className || ""} font-semibold ${bgColor} ${hoverBgColor} ${textColor} ${textSize} border-2 ${borderColor} ${cornerRadius} ${padding} inline-flex ${itemsGap} items-center`;
  if (themeArea === "admin") {
    return <ActionButton bgColorClass="bg-red-dark hover:bg-red-danger" colorClass="text-white" onClick={props.onClick} disabled={props.disabled} leftIcon={props.icon || <TrashIcon className="fill-current" style={trashStyle} />} themeArea="admin">
        {props.children}
      </ActionButton>;
  }
  return <button className={className} type="button" onClick={onClick} disabled={disabled} data-sentry-component="DeleteButton" data-sentry-source-file="DeleteButton.tsx">
      {withIcon && <TrashIcon className="fill-current" style={trashStyle} />}
      {children || "Delete"}
    </button>;
}
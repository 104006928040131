import ActiveMemberRequired from "./ActiveMemberRequired";
import RegistrationRequired from "./RegistrationRequired";
import LoginRequired from "./LoginRequired";
import { AccessRequirements, AccessStatus } from "types/auth";
import UserStore from "stores/UserStore";
interface Props {
  requirements?: AccessRequirements;
  userStore?: UserStore;
}
export default function disallowedContent(props?: Props) {
  const userStore = props?.userStore;
  const member = userStore?.user;
  const status = props && getAccessStatus(props);
  if (status === "loginRequired") {
    return <LoginRequired />;
  } else if (status === "activeMemberRequired") {
    return <ActiveMemberRequired />;
  } else if (status === "forbidden") {
    return <div>Not allowed.</div>;
  } else if (status === "registrationRequired") {
    return <RegistrationRequired user={member} />;
  }
  return null;
}
export function getAccessStatus(props: Props): AccessStatus {
  const {
    requirements
  } = props || {};
  if (!requirements) {
    return "granted";
  }
  const userStore = props?.userStore;
  const member = userStore?.user;
  let status: AccessStatus = "granted";
  const {
    emailRequired,
    memberStatusRequired
  } = requirements;
  if (userStore?.loading) {
    status = "loading";
  } else if (memberStatusRequired && !member) {
    status = "loginRequired";
  } else if (memberStatusRequired === "ACTIVE" && member?.account?.status === "INACTIVE") {
    status = "activeMemberRequired";
  } else if (memberStatusRequired === "ACTIVE" && member?.account?.status && member.account.status === "NON_MEMBER") {
    status = "forbidden";
  } else if (memberStatusRequired === "INACTIVE" && member?.account?.status && !["INACTIVE", "ACTIVE"].includes(member.account.status ?? "")) {
    status = "forbidden";
  } else if (emailRequired && member && !member.account.email) {
    status = "registrationRequired";
  }
  return status;
}
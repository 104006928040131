import clsx from "clsx";
import BackButton from "./BackButton";
import AboutGroupFlowModal from "./AboutGroupFlowModal";
import { useState } from "react";
interface Props {
  backButtonHref?: string;
  children: React.ReactNode;
  level?: "h1" | "h2";
  paddingClass?: string;
  rightSideContent?: React.ReactNode;
  withGroupFlow?: boolean;
}
export default function PageHeading(props: Props) {
  const {
    backButtonHref,
    children,
    paddingClass = "px-4 md:px-0",
    rightSideContent,
    withGroupFlow
  } = props;
  const [aboutIsOpen, setAboutIsOpen] = useState(false);
  let sizeClass = "text-3xl leading-10";
  if (props.level === "h2") {
    sizeClass = "text-2xl leading-9";
  }
  const level = props.level || "h1";
  return <Heading className={clsx("flex flex-col md:flex-row justify-between md:items-center gap-y-4 mb-4", paddingClass)} level={level} data-sentry-element="Heading" data-sentry-component="PageHeading" data-sentry-source-file="PageHeading.tsx">
      <div className={clsx(`flex justify-start items-start gap-x-3 font-bold`)}>
        {backButtonHref && <BackButton href={backButtonHref} />}
        {withGroupFlow && <button title="This website is powered by GroupFlow" onClick={() => setAboutIsOpen(true)} className={clsx("group", level === "h2" ? "w-8 h-8 min-w-8" : "w-10 h-10 min-w-10")}>
            <img src="https://mp1md-pub.s3.amazonaws.com/groupflow/images/icon-white-stroke.svg" alt="GroupFlow" className="opacity-50 group-hover:opacity-100" />
          </button>}
        <span className={sizeClass}>{children}</span>
      </div>
      <AboutGroupFlowModal isOpen={aboutIsOpen} onClose={() => setAboutIsOpen(false)} data-sentry-element="AboutGroupFlowModal" data-sentry-source-file="PageHeading.tsx" />
      <div className="flex justify-end md:justify-end">{rightSideContent}</div>
    </Heading>;
}
function Heading(props: any) {
  if (props.level === "h1") {
    return <h1 {...props} />;
  } else if (props.level === "h2") {
    return <h2 {...props} />;
  }
  return null;
}
import clsx from "clsx";
interface Props {
  children: React.ReactNode;
  className?: string;
}
export default function SectionHeading(props: Props) {
  const {
    children,
    className
  } = props;
  const colorClass = "text-[var(--group-accent-darker)]";
  return <h3 className={clsx("font-bold text-[1.4375rem]", className, colorClass)} data-sentry-component="SectionHeading" data-sentry-source-file="SectionHeading.tsx">
      {children}
    </h3>;
}
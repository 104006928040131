/*!
 * High-level functions for WebComponents.
 *
 * Converts data to React components.
 */

import { Button, ContentSnippet, GroupCopyright, GroupFlowTag, Hero, Image, Link, MarketingCards, Navigation, PageContent, PageQuote, PrimaryNavigation, SecondaryNavigation, WelcomeBackPopUp } from "./webComponent/presets";
import { CONTENT_SNIPPET_ATTRIBUTES, CONTENT_SNIPPET_CONTENT_SCHEMA } from "./webComponent/presets/ContentSnippet";
import { HERO_ATTRIBUTES, HERO_CONTENT_SCHEMA } from "./webComponent/presets/Hero";
import { IMAGE_ATTRIBUTES, IMAGE_CONTENT_SCHEMA } from "./webComponent/presets/Image";
import { MARKETING_CARDS_ATTRIBUTES } from "./webComponent/presets/MarketingCards";
export const WebComponentPresets = [{
  name: "Button",
  component: Button
}, {
  name: "ContentSnippet",
  component: ContentSnippet,
  attributes: CONTENT_SNIPPET_ATTRIBUTES,
  contentSchema: CONTENT_SNIPPET_CONTENT_SCHEMA
}, {
  name: "GroupCopyright",
  component: GroupCopyright
}, {
  name: "GroupFlowTag",
  component: GroupFlowTag
}, {
  name: "Hero",
  component: Hero,
  attributes: HERO_ATTRIBUTES,
  contentSchema: HERO_CONTENT_SCHEMA
}, {
  name: "Image",
  component: Image,
  attributes: IMAGE_ATTRIBUTES,
  contentSchema: IMAGE_CONTENT_SCHEMA
}, {
  name: "Link",
  component: Link
}, {
  name: "MarketingCards",
  component: MarketingCards,
  attributes: MARKETING_CARDS_ATTRIBUTES
}, {
  name: "Navigation",
  component: Navigation
}, {
  name: "PageContent",
  component: PageContent
}, {
  name: "PageQuote",
  component: PageQuote
}, {
  name: "PrimaryNavigation",
  component: PrimaryNavigation
}, {
  name: "SecondaryNavigation",
  component: SecondaryNavigation
}, {
  name: "WelcomeBackPopUp",
  component: WelcomeBackPopUp
}];
export type WebComponentPresetName = (typeof WebComponentPresets)[number]["name"];
export function buildSiteContext() {
  const presets = WebComponentPresets.reduce((acc, preset) => ({
    ...acc,
    [preset.name]: preset.component
  }), {});
  return {
    presets
  };
}
import SimpleModal from "components/SimpleModal";
import LoginForm from "./LoginForm";
import { useLoginModalStore } from "lib/gf-app-context";
import { observer } from "mobx-react-lite";
function LoginModal() {
  const store = useLoginModalStore();
  return <SimpleModal isOpen={store?.isOpen} onClose={() => store?.close()} data-sentry-element="SimpleModal" data-sentry-component="LoginModal" data-sentry-source-file="LoginModal.tsx">
      <LoginForm onCancel={() => store?.close && store?.close()} data-sentry-element="LoginForm" data-sentry-source-file="LoginModal.tsx" />
    </SimpleModal>;
}
export default observer(LoginModal);
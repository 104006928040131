// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import ExifRestorer from "./exif-restorer";

export function readURL(imageEl: HTMLImageElement, file: File) {
  const reader = new window.FileReader();

  reader.onload = function (e: any) {
    imageEl.setAttribute("src", e.target.result);
  };

  reader.readAsDataURL(file); // convert to base64 string
}

// https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
export function base64toBytes(base64Data: string) {
  const sliceSize = 1024;
  const byteCharacters = window.atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return byteArrays;
}

/**
 * Given a file or a fileList of images reduce the resolution image or first image to a smaller, lower
 * quality version
 * @param fileOrList
 * @param maxResolution
 * @param quality
 * @returns {Promise<File>}
 */
export const resizeImageFile = async (
  fileToProcess: File,
  maxResolution: number, // 1024
  quality: number // 0.8
): Promise<File> => {
  // Read the file to a DataURL
  const origDataUrl = await readFileAsDataUrl(fileToProcess);
  // load image from data url
  const imageObj = await loadImage(origDataUrl);
  const { width: resizedWidth, height: resizedHeight } = clampImageResolution(
    imageObj,
    maxResolution
  );

  const canvas = document.createElement("canvas");
  // update drawing canvas
  canvas.width = resizedWidth;
  canvas.height = resizedHeight;
  // Create image context
  const context = canvas.getContext("2d");

  if (!context) throw new Error("Failed to get 2d context from canvas");

  // draw the image at the new reduced size
  context.drawImage(
    imageObj,
    0,
    0,
    imageObj.width,
    imageObj.height,
    0,
    0,
    resizedWidth,
    resizedHeight
  );

  // Convert back to a blob and resolve with the file
  const blob = await canvasToBlob(canvas, fileToProcess.type, quality);
  // persist exif data
  if (fileToProcess.type === "image/jpeg") {
    const blobDataUrl = await readFileAsDataUrl(blob);
    const resizedWithExif = new ExifRestorer().restore(
      origDataUrl,
      blobDataUrl
    );

    return new window.File(
      [
        new window.Blob(base64toBytes(resizedWithExif), {
          type: fileToProcess.type,
        }),
      ],
      `${fileToProcess.name}`,
      {
        type: fileToProcess.type,
      }
    );
  }

  return new window.File([blob], `${fileToProcess.name}`, {
    type: fileToProcess.type,
  });
};

export function clampImageResolution(image: any, maxResolution: number) {
  const origWidth = image.width;
  const origHeight = image.height;
  let newHeight;
  let newWidth;
  if (origWidth > origHeight) {
    const aspectRatio = image.width / image.height;
    newWidth = maxResolution;
    newHeight = newWidth / aspectRatio;
  } else {
    const aspectRatio = image.height / image.width;
    newHeight = maxResolution;
    newWidth = newHeight / aspectRatio;
  }

  return { width: newWidth, height: newHeight };
}

export function readFileAsDataUrl(file: File | Blob): Promise<string> {
  const reader = new window.FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.addEventListener(
      "load",
      () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        }
        reject(new Error("Failed to get result from file read"));
      },
      false
    );
    reader.addEventListener(
      "error",
      () => {
        reject(reader.error);
      },
      false
    );
  });
}

function loadImage(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const image = new window.Image();
    image.addEventListener(
      "load",
      () => {
        resolve(image);
      },
      true
    );
    image.onerror = (err) => {
      reject(err);
    };
    image.src = url;
  });
}

export function canvasToBlob(
  canvas: HTMLCanvasElement,
  type: string,
  quality: number
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Failed to convert canvas to blob"));
        }
      },
      type,
      quality
    );
  });
}
